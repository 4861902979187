import React, { useState, useEffect } from "react";
import { constants as CONST } from "../../../constants/buttonconstant.js";
import { constants as ROUTE_CONST } from "constants/routes";
import { getAllJobs } from "../../../services/Job";
import { PaginationFunction } from "../../../components/PaginationFunction.jsx";
import { useHistory } from "react-router-dom";
import { Card, Table } from "reactstrap";
import GetJob from "./GetJob.jsx";
import EmptyView from "../Dashboard/EmptyView.jsx";

const columns = [
  { name: "Job ID", width: "15%" },
  { name: "Description", width: "25%" },
  { name: "Location", width: "20%" },
  { name: "Date & Time", width: "20%" },
  { name: "Duration", width: "10%" },
  { name: "Status", width: "10%" },
];

const statusFunction = (jobStatus) => {
  return (
    <div
      className={
        "border rounded d-flex justify-content-center align-items-center " +
        jobStatus
      }
      style={{
        width: "88px",
        fontSize: "14px",
        padding: "8px",
        color: "#FFFFFF",
      }}
    >
      {jobStatus === CONST.NEW_JOB
        ? "New Job"
        : jobStatus === CONST.PENDING_VERIFICATION
        ? "Pending"
        : jobStatus === CONST.PENDING_DISPUTE_RESOLUTION
        ? "Dispute"
        : jobStatus === CONST.COMPLETED
        ? "Completed"
        : jobStatus === CONST.CANCELED
        ? "Canceled"
        : jobStatus === CONST.EXPIRED
        ? "Expired"
        : jobStatus === CONST.TAKEN
        ? "Taken"
        : jobStatus}
    </div>
  );
};

const JobListTable = (props) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const perPage = 10;
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    fetchData(1, perPage);
  }, [props.month, props.filterStatus]);

  const history = useHistory();
  const jobDetailPage = (id) => {
    history.push(`${ROUTE_CONST.JOBS}${ROUTE_CONST.JOB_DETAIL}?id=${id}`);
  };

  const fetchData = async (page, limit) => {
    const params = {
      ...props.month,
      ...props.filterStatus,
      page,
      limit,
      field: "dateTime",
    };
    getAllJobs(params).then(
      (result) => {
        setIsLoaded(true);
        setItems(result.data.data.data);
        setTotalRows(result.data.data.count);
      },
      (err) => {
        const errorMessage =
          err && err.data && err.data.error && err.data.error.message;
        setIsLoaded(true);
        setError(true);
        if (!err.data) {
          setErrorMessage("Network Error");
        } else {
          setErrorMessage(errorMessage);
        }
      }
    );
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    fetchData(page, perPage);
  };

  if (error) {
    return <div className="text-danger pl-3">Error: {errorMessage}</div>;
  } else if (!isLoaded) {
    return <div className="pl-3">Loading...</div>;
  } else {
    return (
      <div className="rounded mt-3">
        <Card style={{ borderRadius: "8px" }}>
          <Table className="bordered_table">
            <thead>
              <tr>
                {columns.map((column, index) => {
                  return (
                    <th style={{ width: column.width }} key={index}>
                      {" "}
                      {column.name}{" "}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {items.length === 0 && (
                <tr>
                  <td colSpan={25}>
                    <EmptyView
                      title={"Sorry!"}
                      discription={"No Shift Found"}
                      bgcolor={"white"}
                      icon={"mdi mdi-account"}
                    ></EmptyView>
                  </td>
                </tr>
              )}
              {items.map((job, index) => {
                return (
                  <tr
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => jobDetailPage(job.id)}
                  >
                    <GetJob jobData={job} statusFunction={statusFunction} />
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
        {items.length !== 0 ? (
          <div colSpan={12}>
            <div
              style={{
                width: "fit-content",
                display: "flex",
                justifyItems: "flex-start",
              }}
            >
              <PaginationFunction
                activePage={activePage}
                totalCount={totalRows}
                handlePageChange={handlePageChange}
                pageDataCount={perPage}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
};

export default JobListTable;
