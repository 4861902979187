import React from 'react'
import { Button } from 'reactstrap'
import './style.css'
const CalendarHeader = ({ onNext, onBack, dateDisplay, currentDate }) => {
    return (
        <div id="header">
            <h3 id="monthDisplay">{dateDisplay}</h3>
            <div>
                <Button
                    onClick={onBack}
                    color="primary"
                    type="button"
                    id="backButton"
                    style={{ backgroundColor: "transparent", border: "none", boxShadow: "none" }}
                >
                    <svg
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6.57963 13.799L0.164491 7.38381C0.103568 7.32289 0.0609225 7.26197 0.0365535 7.20104C0.0121845 7.14012 0 7.07311 0 7C0 6.92689 0.0121845 6.85988 0.0365535 6.79896C0.0609225 6.73803 0.103568 6.67711 0.164491 6.61619L6.57963 0.201044C6.71366 0.0670149 6.88425 0 7.09138 0C7.29852 0 7.4691 0.0670149 7.60313 0.201044C7.74935 0.347259 7.82245 0.520888 7.82245 0.721932C7.82245 0.922976 7.74935 1.09661 7.60313 1.24282L1.84595 7L7.60313 12.7572C7.74935 12.9034 7.81941 13.077 7.81331 13.2781C7.80722 13.4791 7.73716 13.6466 7.60313 13.7807C7.45692 13.9269 7.28329 14 7.08224 14C6.8812 14 6.71366 13.933 6.57963 13.799Z"
                            fill="#4E79EE"
                        />
                    </svg>
                </Button>
                {/* <button onClick={onBack} id="backButton">Back</button> */}
                <Button
                    color="primary"
                    onClick={onNext}
                    type="button"
                    id="nextButton"
                    style={{ backgroundColor: "transparent", border: "none", boxShadow: "none" }}
                >
                    <svg
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.210405 13.7808C0.0764667 13.6347 0.00645341 13.4642 0.000365288 13.2694C-0.00572283 13.0746 0.0642905 12.9041 0.210405 12.758L5.96368 7.00475L0.210405 1.25147C0.0764667 1.11754 0.00645341 0.950112 0.000365288 0.749204C-0.00572283 0.548296 0.0642905 0.374785 0.210405 0.22867C0.344344 0.0825549 0.511767 0.00645341 0.712675 0.000365287C0.913583 -0.00572283 1.08709 0.0642907 1.23321 0.210406L7.644 6.6212C7.70488 6.68208 7.7475 6.74296 7.77185 6.80384C7.7962 6.86472 7.80838 6.93169 7.80838 7.00475C7.80838 7.07781 7.7962 7.14478 7.77185 7.20566C7.7475 7.26654 7.70488 7.32742 7.644 7.3883L1.23321 13.7991C1.09927 13.933 0.931848 14 0.73094 14C0.530032 14 0.35652 13.9269 0.210405 13.7808Z"
                            fill="#4E79EE"
                        />
                    </svg>
                </Button>
                {/* <button onClick={onNext} id="nextButton">Next</button> */}
            </div>
        </div>
    )
}

export default CalendarHeader