import React from "react"; // Import React

import { Row, Col } from "reactstrap";
import DashboardAcceptedShift from "./DashboardAcceptedShift.jsx";
import DashboardEndShift from "./DashboardEndShift.jsx";
import ShiftCount from "./ShiftCount.jsx";
import DashboardOnShift from "./DashboardOnShift.jsx";

const Dashboard = () => {
  return (
    <div>
      <div className="content">
        <Row>
          <div className="page-title">
            <div className="float-left">
              <h1 className="title ml-3">Dashboard</h1>
            </div>
          </div>

          <Col xs={12} md={12}>
            <div className="row p-3">
              <ShiftCount title="Shift in Progress" shiftStatus="on_shift" />
              <ShiftCount
                title="Upcoming Job (24 hours)"
                shiftStatus="accepted"
              />
              <ShiftCount title="Completed Job" shiftStatus="completed" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title float-left">Shift in Progress</h2>
                </header>
                <div className="row">
                  <div className="col-lg-12 ">
                    <DashboardOnShift />
                  </div>
                </div>
              </section>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={6}>
            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title float-left font-weight-bold">
                    Pending Verification
                  </h2>
                </header>
                <div className="row">
                  <div className="col-lg-12">
                    <DashboardEndShift />
                  </div>
                </div>
              </section>
            </div>
          </Col>
          <Col xs={6} md={6}>
            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title float-left font-weight-bold">
                    Upcoming Job
                  </h2>
                </header>
                <div className="row">
                  <div className="col-lg-12">
                    <DashboardAcceptedShift />
                  </div>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;
