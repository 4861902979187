import React from "react";
import { useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import VerificationSuccessModal from "./VerificationSuccessModal";
import { constants as CONST } from "../../../constants/constants";
import { constants as ROUTE_CONST } from "../../../constants/routes.js";
import Completed from "assets/img/Completed.png";
import { Link } from "react-router-dom";

const Verification = (props) => {
  const [popUpSuccess, setPopUpSuccess] = useState(true);
  const openSuccessModal = () => {
    setPopUpSuccess(!popUpSuccess);
  };
  const path = window.location.pathname.split("/");
  const page = path[path.length - 1];
  return (
    <div>
      {page === "success" && (
        <VerificationSuccessModal
          modal={popUpSuccess}
          toggle={openSuccessModal}
        />
      )}
      {page === "failure" && (
        <Modal
          isOpen={popUpSuccess}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <ModalBody>
            <div className="popup">
              <img src={Completed} alt="logo" />
              <h3>{CONST.ACCOUNT_VERIFICATION_FAILURE}</h3>
              <br></br>
              <p style={{ color: "#414248" }}>
                {CONST.VERIFICATION_FAILURE_MESSAGE}
              </p>
              <Button color="yellow" className="btn" onClick={openSuccessModal}>
                <Link
                  to={`${ROUTE_CONST.REGISTER}`}
                  style={{ textDecoration: "none", color: "#FFFFFF" }}
                >
                  {CONST.SIGN_UP}
                </Link>
              </Button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default Verification;
