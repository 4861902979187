import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { getAllJobs } from "services/Job.js";

const JobsCount = (props) => {
  const params = {
    ...props.month,
    status: props.status,
    field: "dateTime",
  };
  const [count, setCount] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetchData();
  }, [props.month]);

  const fetchData = async () => {
    getAllJobs(params).then(
      (result) => {
        setCount(result.data.data.count);
        setIsLoaded(true);
      },
      (_error) => {
        setIsLoaded(true);
      }
    );
  };

  return (
    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
      <Card>
        <CardBody>
          <span className="w-400">{props.title}</span>
          {!isLoaded && <div className="pl-3">Loading...</div>}
          {isLoaded && <h2 className="">{count}</h2>}
        </CardBody>
      </Card>
    </div>
  );
};

export default JobsCount;
