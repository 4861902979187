import React, { useState } from "react";
import { Row, Col, Label, Button } from "reactstrap";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import loginLogo from "../../../assets/img/login.png";
import logoGod from "../../../assets/img/logoGod.png";
import { constants as CONST } from "../../../constants/constants.js";
import { constants as ERROR_CONST } from "../../../constants/errorMessages.js";
import { constants as ROUTE_CONST } from "../../../constants/routes.js";
import * as Yup from "yup";
import AuthInput from "components/Auth/AuthInput.jsx";
import { userAuthLogin } from "services/Auth.js";
import ForgotModal from "./forgotPassword";

const Login = (props) => {
  const LogInSchema = Yup.object().shape({
    email: Yup.string()
      .email(ERROR_CONST.VALID_EMAIL)
      .required(ERROR_CONST.EMAIL_REQUIRED),
    password: Yup.string()
      .required(ERROR_CONST.PASSWORD_REQUIRED)
      .min(8, ERROR_CONST.PASSWORD_LENGTH),
  });

  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const handleSubmit = (values) => {
    const data = {
      email: values.email.toLowerCase(),
      password: values.password,
    };
    setError(false);
    userAuthLogin(data)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        localStorage.setItem(CONST.ACCESSTOKEN, data.tokens);
        localStorage.setItem(CONST.EMAIL, data.email);
        props.history.push({ pathname: `${ROUTE_CONST.DASHBOARD}` });

        // stopLoading();
      })
      .catch((err) => {
        // stopLoading();
        const errorMessage =
          err && err.data && err.data.error && err.data.error.message;
        setError(true);
        setMessage(errorMessage);
        // notifyError(notificationMessageFunction(errorMessage));
      });
  };

  const [modal, setModal] = useState(false);
  const openModal = () => {
    setModal(!modal);
  };

  return (
    <div className="wrapper ">
      <div className="maincompo">
        <Row>
          <Col lg={6} md={6} className="midcompo">
            <div className="text-center login-sidecompo">
              <img
                src={logoGod}
                alt="logo"
                className="mb-4"
                style={{ marginTop: 150 }}
              />
              <img src={loginLogo} alt="logo" className="mt-4" />
            </div>
          </Col>
          <Col lg={6} md={6} className="midcompo">
            <div className="rightcompo">
              <div className="register-wrapper">
                <div
                  id="register"
                  className="registerpage  shadow"
                  height="464px"
                >
                  <h3>{CONST.LOG_IN}</h3>
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    validationSchema={LogInSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ errors, touched, values, handleChange }) => (
                      <Form name="loginform" id="loginform">
                        <div>
                          <Label htmlFor="user_login">
                            {CONST.EMAIL}
                            <br />
                            <AuthInput
                              type="text"
                              name="email"
                              id="user_name"
                              className="form-control"
                              placeholder="Insert email"
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                              values={values}
                            />
                          </Label>
                        </div>
                        <div className="mt-4">
                          <Label htmlFor="user_pass">
                            {CONST.PASSWORD}
                            <br />
                            <AuthInput
                              type="password"
                              name="password"
                              id="user_pass"
                              className="input"
                              size="20"
                              placeholder="Insert password"
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                              values={values}
                            />
                          </Label>
                        </div>
                        <div>
                          {error && (
                            <span className="text-danger">{message}</span>
                          )}
                        </div>
                        {/* <p className="submit"> */}
                        <Button
                          type="submit"
                          name="wp-submit"
                          id="wp-submit"
                          className={`btn ${
                            values.email !== undefined &&
                            values.email.trim() !== "" &&
                            values.password.trim() !== "" &&
                            values.password !== undefined
                              ? "btn-color"
                              : "btn-accent"
                          } mt-4`}
                          style={{ padding: "10px 25px 10px 25px" }}
                          onSubmit={(values) => handleSubmit(values)}
                        >
                          Log In
                        </Button>
                        {/* </p> */}
                      </Form>
                    )}
                  </Formik>

                  <p
                    id="nav"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      to="#"
                      onClick={openModal}
                      title="Password Lost and Found"
                    >
                      {CONST.FORGOT}
                    </Link>
                    <br />
                    <Link to={ROUTE_CONST.REGISTER} title="Sign Up">
                      {CONST.SIGN_UP}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {ForgotModal(modal, openModal)}
      </div>
    </div>
  );
};

export default Login;
