import React, { useState } from "react";
import { Row, Col, Modal, ModalBody, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { constants as CONST } from "../../constants/constants.js";
import { constants as ERROR_CONST } from "../../constants/errorMessages.js";
import completed from "assets/img/Completed.png";
import AuthInput from "components/Auth/AuthInput.jsx";
import { UpdateMyProfileAPI } from "services/Auth";
import moment from "moment";

const EditAgencyProfile = (props) => {
  const data = props.data;
  const [Submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFilePreview, setSelectedFilePreiview] = useState(
    data.profileImage
      ? data.profileImage
      : "/images/AvatarPlaceholder-Agency.png"
  );
  const history = useHistory();
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setSelectedFilePreiview(URL.createObjectURL(event.target.files[0]));
  };
  const handleSubmit = (values) => {
    const payloadData = new FormData();
    if (selectedFile !== undefined) {
      payloadData.append("profileImage", selectedFile);
    }
    payloadData.append("agencyName", values.agencyName);
    payloadData.append("address", values.address);
    payloadData.append("uen", values.uen);
    payloadData.append("agencyLicense", values.agencyLicense);
    payloadData.append("licenseExpireDate", values.licenseExpireDate);
    UpdateMyProfileAPI(payloadData)
      .then((res) => {
        setSubmitted(true);
      })
      .catch((error) => {
        setError(true);
      });
  };
  const date = moment(data.licenseExpireDate).format("YYYY-MM-DD");
  data.licenseExpireDate = date;

  const validationSchema = Yup.object().shape({
    agencyName: Yup.string()
      .min(3)
      .max(50)
      .matches(/^[^\s]+(\s+[^\s]+)*$/, ERROR_CONST.SPACE_NOT_ALLOWED)
      .required(ERROR_CONST.AGENCY_NAME),
    address: Yup.string()
      .min(7)
      .max(100)
      .matches(/^[^\s]+(\s+[^\s]+)*$/, ERROR_CONST.SPACE_NOT_ALLOWED)
      .required(ERROR_CONST.ADDRESS_REQUIRED),
    uen: Yup.string()
      .max(25)
      .matches(/^[^\s]+(\s+[^\s]+)*$/, ERROR_CONST.SPACE_NOT_ALLOWED)
      .required(),
    agencyLicense: Yup.string()
      .max(25)
      .matches(/^[^\s]+(\s+[^\s]+)*$/, ERROR_CONST.SPACE_NOT_ALLOWED)
      .required(),
    licenseExpireDate: Yup.date()
      .min(moment(new Date()), ERROR_CONST.LICENSE_EXPIRY_REQUIRED)
      .required(ERROR_CONST.DATE_REUIRED),
  });

  const clickNavigation = () => {
    document.getElementById("profileImage").click();
  };
  return (
    <>
      <Row>
        <Col xs={12} md={12}>
          <div className="col-12 box font-weight-bold">
            <h1>{CONST.EDIT_AGENCY_PROFILE}</h1>
          </div>
          <div className="col-12">
            <section className="box ">
              <header className="panel_header">
                <div className="page-sub-title">
                  <h1 className="float-left">{CONST.AGENCY_PROFILE_DETAILS}</h1>
                  {error && (
                    <p className="title text-danger">
                      {" "}
                      {ERROR_CONST.PROFILE_NOT_UPDATED}
                    </p>
                  )}
                </div>
              </header>
              <div className="content-body">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                    <Formik
                      initialValues={{
                        agencyName: data.agencyName ? data.agencyName : "",
                        address: data.address ? data.address : "",
                        uen: data.uen ? data.uen : "",
                        agencyLicense: data.agencyLicense
                          ? data.agencyLicense
                          : "",
                        licenseExpireDate: data.licenseExpireDate
                          ? data.licenseExpireDate
                          : "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ errors, touched, values, handleChange }) => (
                        <Form>
                          <div className="form-group row h-20">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-4 col-form-label"
                            >
                              {CONST.LOGO}
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="file"
                                name="profileImage"
                                id="profileImage"
                                className="input rounded"
                                size="20"
                                onChange={changeHandler}
                                hidden
                              />
                              <img
                                src={selectedFilePreview}
                                alt="profileImage here"
                                id="proImage"
                                width="100px"
                                height="100px"
                                onClick={clickNavigation}
                                className="border"
                              />
                            </div>
                          </div>
                          <div className="form-group row h-20">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-4 col-form-label"
                            >
                              {CONST.AGENCY}
                            </label>
                            <div className="col-sm-8">
                              <AuthInput
                                type="text"
                                name="agencyName"
                                id=""
                                className="input rounded"
                                size="20"
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}
                                values={values}
                              />
                            </div>
                          </div>
                          <div className="form-group row h-20">
                            <label
                              htmlFor="inputPassword3"
                              className="col-sm-4 col-form-label"
                            >
                              {CONST.ADDRESS}
                            </label>
                            <div className="col-sm-8">
                              <AuthInput
                                type="text"
                                name="address"
                                id=""
                                className="input"
                                size="20"
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}
                                values={values}
                              />
                            </div>
                          </div>
                          <div className="form-group row h-20">
                            <label
                              htmlFor="inputPassword3"
                              className="col-sm-4 col-form-label"
                            >
                              {CONST.UEN}
                            </label>
                            <div className="col-sm-8">
                              <AuthInput
                                type="text"
                                name="uen"
                                id=""
                                className="input"
                                size="20"
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}
                                values={values}
                              />
                            </div>
                          </div>
                          <div className="form-group row h-20">
                            <label
                              htmlFor="inputPassword3"
                              className="col-sm-4 col-form-label"
                            >
                              {CONST.AGENCY_LICENSE}
                            </label>
                            <div className="col-sm-8">
                              <AuthInput
                                type="text"
                                name="agencyLicense"
                                id=""
                                className="input"
                                size="20"
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}
                                values={values}
                              />
                            </div>
                          </div>
                          <div className="form-group row primary">
                            <label
                              htmlFor="inputPassword3"
                              className="col-sm-4 col-form-label"
                            >
                              {CONST.LICENSE_EXPIRY}
                            </label>
                            <div className="col-sm-8">
                              <AuthInput
                                type="date"
                                name="licenseExpireDate"
                                id=""
                                className="input"
                                size="20"
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}
                                values={values}
                              />
                            </div>
                          </div>
                          <div>
                            <p className="submit float-right primary">
                              <input
                                type="submit"
                                name="wp-submit"
                                id="wp-submit"
                                className="btn"
                                style={{ width: "82px", height: "40px" }}
                                value="SAVE"
                                onSubmit={(values) => handleSubmit(values)}
                              />
                            </p>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Col>
      </Row>
      {Submitted && (
        <Row id="dialguebox">
          <div>
            <div>
              <Modal
                isOpen={Submitted}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <ModalBody>
                  <div className="editpopup">
                    <img src={completed} alt="logo" />
                    <br></br>
                    <h3>Agency profile updated</h3>
                    <Button
                      color="yellow"
                      className="btn mt-2"
                      style={{ width: "66px" }}
                      onClick={() => {
                        props.onSubmit();
                        history.go("/agency_profile");
                      }}
                    >
                      {CONST.OK}
                    </Button>
                  </div>
                </ModalBody>
              </Modal>
            </div>
          </div>
        </Row>
      )}
    </>
  );
};

export default EditAgencyProfile;
