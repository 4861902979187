import React, { useState } from "react";
import { Formik, Form } from "formik";
import { Col, Modal, ModalBody, Label, Input } from "reactstrap";
import * as Yup from "yup";
import { resolveDispute } from "../../../services/Job.js";

const EnterNewAmount = (props) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const handleSubmit = (values) => {
    const data = {
      new_payout: values.newamount,
    };
    resolveDispute(props.job.id, data)
      .then((res) => {
        props.toggle();
        props.onSubmit();
      })
      .catch((err) => {
        setError(true);
        if (!err.data) {
          setMessage("Network Error");
        } else {
          setMessage(err.data.error.message);
        }
      });
  };

  const formSchema = Yup.object().shape({
    newamount: Yup.number("only number allowed")
      .positive("Amount should be positive")
      .required("New Amount is required"),
  });

  return (
    <Modal
      isOpen={props.modal}
      fade={false}
      toggle={props.toggle}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody className="d-flex flex-column align-items-center rounded">
        <Col lg={12} md={12}>
          <div className="content d-flex flex-column align-items-center">
            <h1>Enter New Amount</h1>
            <br />
            <Formik
              initialValues={{
                newamount: 0,
              }}
              validationSchema={formSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, values, handleChange }) => (
                <Form className="d-flex flex-column align-items-center login loginpage">
                  <div className="d-flex flex-column align-left">
                    <Label>New Payout Amount</Label>
                    <input
                      type="number"
                      id="name"
                      className="form-control rounded"
                      name="newamount"
                      placeholder="Enter expense title"
                      onChange={handleChange}
                      value={values.newamount}
                    />
                    <br />
                    <Label>Credit to be Deducted</Label>

                    <Input
                      type="text"
                      id="name"
                      className=" input"
                      name="credit"
                      placeholder="Enter expense title"
                      onChange={handleChange}
                      value={Number(values.newamount) * 1.1}
                      readOnly={true}
                    />
                    {!error && <br />}
                    {!error && <br />}
                  </div>
                  {errors["newamount"] && touched["newamount"] && (
                    <span
                      style={{ fontSize: 12 }}
                      className="text-left mt-1 text-danger"
                    >
                      {errors["newamount"]}
                    </span>
                  )}
                  {error && <p className="text-danger">{message}</p>}
                  <input
                    type="submit"
                    name="wp-submit"
                    id="wp-submit"
                    className="btn btn-accent"
                    value="Mark As Completed"
                    onSubmit={(values) => handleSubmit(values)}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </ModalBody>
    </Modal>
  );
};

export default EnterNewAmount;
