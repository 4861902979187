import React, { useState } from "react";
import { Row, Col, Input } from "reactstrap";
import { Formik, Form } from "formik";
import { constants as CONST } from "../../../constants/constants.js";
import { constants as ERROR_CONST } from "../../../constants/errorMessages.js";
import * as Yup from "yup";
import loginLogo from "../../../assets/img/login.png";
import logoGod from "../../../assets/img/logoGod.png";
import { userAuthRegister } from "services/Auth.js";
import AuthInput from "components/Auth/AuthInput.jsx";

import EmailPopup from "./emailpopup";
import { Link } from "react-router-dom";
import { constants as ROUTE_CONST } from "../../../constants/routes.js";

const Register = (props) => {
  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email(ERROR_CONST.VALID_EMAIL)
      .required(ERROR_CONST.EMAIL_REQUIRED),
    password: Yup.string()
      .required(ERROR_CONST.PASSWORD_REQUIRED)
      .min(8, ERROR_CONST.PASSWORD_LENGTH)
      .matches(/^\S*$/, ERROR_CONST.SPACE_NOT_ALLOWED),
    confirm_password: Yup.string()
      .required(ERROR_CONST.CONFIRM_PASSWORD_REQUIRED)
      .min(8, ERROR_CONST.PASSWORD_LENGTH)
      .oneOf([Yup.ref("password"), null], ERROR_CONST.PASSWORD_MATCH),
    agency_name: Yup.string().required(ERROR_CONST.AGENCY_NAME),
  });

  const [modal, setModal] = useState(false);
  const toggle = () => {
    if (modal) {
      props.history.push({ pathname: `${ROUTE_CONST.LOGIN}` });
    }
    setModal(!modal);
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  const handleSubmit = (values) => {
    const data = {
      email: values.email.toLowerCase(),
      password: values.password,
      agencyName: values.agency_name,
    };
    setError(false);
    userAuthRegister(data)
      .then((res) => {
        const data = res && res.data && res.data.data;
        localStorage.setItem(CONST.EMAIL, data.email);
        setModal(true);
      })
      .catch((err) => {
        const error = err && err.data && err.data.error.message;
        setError(true);
        setErrorMessage(error);
      });
  };
  return (
    <div className="wrapper ">
      <div className="">
        <div className="maincompo">
          <Row>
            <Col lg={6} md={6} className="midcompo">
              <div className="text-center sidecompo">
                <img
                  src={logoGod}
                  alt="logo"
                  className="mb-4"
                  style={{ marginTop: 150 }}
                />
                <img src={loginLogo} alt="logo" className="mt-4" />
              </div>
            </Col>
            <Col lg={6} md={6} className="midcompo">
              <div className="rightcompo">
                <div className="register-wrapper">
                  <div
                    id="register"
                    className="registerpage  shadow"
                    height="640px"
                  >
                    <h3>{CONST.SIGN_UP}</h3>
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                        confirm_password: "",
                        agency_name: "",
                      }}
                      validationSchema={RegisterSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ errors, touched, values, handleChange }) => (
                        <Form name="loginform" id="loginform">
                          <div className="mt-4">
                            <label htmlFor="user_login2">
                              {CONST.EMAIL}
                              <br />
                              <AuthInput
                                type="text"
                                name="email"
                                id="user_login2"
                                className="input"
                                size="20"
                                handleChange={handleChange}
                                placeholder="Insert email"
                                errors={errors}
                                touched={touched}
                                values={values}
                              />
                            </label>
                          </div>
                          <div className="mt-4">
                            <label htmlFor="user_login3">
                              {CONST.AGENCY}
                              <br />
                              <div className="has-wrapper">
                                <Input
                                  type="text"
                                  name="agency_name"
                                  id="user_login3"
                                  className="input"
                                  size="20"
                                  value={values.agency_name}
                                  placeholder="Insert agency name"
                                  onChange={handleChange}
                                />
                                {errors["agency_name"] &&
                                  touched["agency_name"] && (
                                    <span
                                      style={{ fontSize: 12 }}
                                      className="text-left mt-1 text-danger"
                                    >
                                      {errors["agency_name"]}
                                    </span>
                                  )}
                              </div>
                            </label>
                          </div>
                          <div className="mt-4">
                            <label htmlFor="user_pass">
                              {CONST.PASSWORD}
                              <br />
                              <AuthInput
                                type="password"
                                name="password"
                                id="user_pass"
                                className="input"
                                size="20"
                                placeholder="Insert password"
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}
                                values={values}
                              />
                            </label>
                          </div>
                          <div className="mt-4">
                            <label htmlFor="user_pass2">
                              {CONST.CONFIRM_PASSWORD}
                              <br />
                              <AuthInput
                                type="password"
                                name="confirm_password"
                                id="user_pass2"
                                className="input"
                                size="20"
                                placeholder="Insert password"
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}
                                values={values}
                              />
                            </label>
                          </div>
                          <div>
                            {error && (
                              <span className="text-danger">
                                {errorMessage}
                              </span>
                            )}
                          </div>
                          <p className="submit mt-3">
                            <input
                              type="submit"
                              name="wp-submit"
                              id="wp-submit"
                              className={`btn ${
                                values.email !== undefined &&
                                values.email.trim() !== "" &&
                                values.password.trim() !== "" &&
                                values.password !== undefined &&
                                values.confirm_password.trim() !== "" &&
                                values.agency_name.trim() !== ""
                                  ? "btn-color"
                                  : "btn-accent"
                              } mt-4`}
                              style={{ padding: "10px 25px 10px 25px" }}
                              value="Sign Up"
                              onSubmit={(values) => handleSubmit(values)}
                            />
                          </p>
                        </Form>
                      )}
                    </Formik>

                    <p id="nav">
                      {CONST.ALREADY_ACCOUNT}{" "}
                      <Link to={"/login"} title="Login">
                        {CONST.ALREADY_LOGIN}
                      </Link>
                    </p>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {EmailPopup(
            modal,
            toggle,
            CONST.EMAIL_VERIFICATION_SENT_TITLE,
            CONST.EMAIL_VERIFICATION_SENT_MESSAGE
          )}
        </div>
      </div>
    </div>
  );
};

export default Register;
