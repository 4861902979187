import React, { useEffect, useMemo, useState } from "react";
import CalendarHeader from "./CalendarHeader";
import Day from "./Day";
import { useDate } from "./useDate";
import "./style.css";
import moment from "moment";
import { getAllJobs } from "../../services/Job";

const Calendar = ({ currentDate, nav, setNav }) => {
  const [clicked, setClicked] = useState();
  const [events, setEvents] = useState(
    localStorage.getItem("events")
      ? JSON.parse(localStorage.getItem("events"))
      : []
  );
  const [items, setItems] = useState([]);
  const eventForDate = (date) => events.find((e) => e.date === date);

  const fetchData = async () => {
    const params = {
      ...month,
      field: "dateTime",
    };
    getAllJobs().then(
      (result) => {
        setItems(result.data.data.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const month = useMemo(
    () => ({
      startDate: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
      endDate: moment(new Date()).endOf("month").format("YYYY-MM-DD"),
    }),
    []
  );

  useEffect(() => {
    fetchData();
  }, [month]);

  useEffect(() => {
    localStorage.setItem("events", JSON.stringify(events));
  }, [events]);

  const { days, dateDisplay } = useDate(events, nav, currentDate, clicked);

  return (
    <div>
      <CalendarHeader
        dateDisplay={dateDisplay}
        onNext={() => setNav(nav + 1)}
        onBack={() => setNav(nav - 1)}
      />

      <div id="weekdays">
        <div>Sunday</div>
        <div>Monday</div>
        <div>Tuesday</div>
        <div>Wednesday</div>
        <div>Thursday</div>
        <div>Friday</div>
        <div>Saturday</div>
      </div>

      <div id="calendar">
        {days.map((d, index) => (
          <Day
            currentDate={currentDate}
            key={index}
            clicked={clicked}
            items={items}
            day={d}
            setClicked={setClicked}
            eventForDate={eventForDate}
            onClick={() => {
              if (d.value !== "padding") {
                setClicked(d.date);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Calendar;
