import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { Formik, Form } from "formik";
import { constants as CONST } from "../../../constants/constants.js";
import { constants as ERROR_CONST } from "../../../constants/errorMessages.js";
import * as Yup from "yup";
import loginLogo from "../../../assets/img/login.png";
import vectorImg from "../../../assets/img/Vector.png";
import AuthInput from "components/Auth/AuthInput.jsx";
import MessageModal from "../../../components/Sidebar/MessageModal.jsx";
import { constants as ROUTE_CONST } from "../../../constants/routes.js";

import queryString from "query-string";
import { userResetPassword } from "../../../services/Auth.js";
import ExpiredLinkModal from "./ExpiredLinkModal.jsx";

const ResetPassword = (props) => {
  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required(ERROR_CONST.PASSWORD_REQUIRED)
      .min(8, ERROR_CONST.PASSWORD_LENGTH)
      .matches(/^\S*$/, ERROR_CONST.SPACE_NOT_ALLOWED),
    confirm_password: Yup.string()
      .required(ERROR_CONST.CONFIRM_PASSWORD_REQUIRED)
      .min(8, ERROR_CONST.PASSWORD_LENGTH)
      .oneOf([Yup.ref("password"), null], ERROR_CONST.PASSWORD_MATCH),
  });

  const [modal, setModal] = useState(false);
  const [linkExpired, setLinkExpired] = useState(false);
  const toggle = () => {
    if (modal) {
      props.history.push({ pathname: `${ROUTE_CONST.LOGIN}` });
    }
    setModal(!modal);
  };
  const linkExpiredToggle = () => {
    if (linkExpired) {
      props.history.push({ pathname: `${ROUTE_CONST.LOGIN}` });
    }
    setModal(!linkExpired);
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const handleSubmit = (values) => {
    let queries = queryString.parse(props.location.search);
    const data = {
      password: values.password,
      code: queries.token,
    };
    userResetPassword(data)
      .then((response) => {
        setModal(true);
      })
      .catch((err) => {
        const errorMessage =
          err && err.data && err.data.error && err.data.error.message;
        if (
          err.status === 400 &&
          errorMessage &&
          errorMessage === "Invalid token"
        ) {
          setLinkExpired(true);
        } else {
          setError(true);
          setErrorMessage(errorMessage);
        }
      });
  };
  return (
    <div className="">
      <div className="">
        <div className="">
          <Row className="d-flex align-items-center" style={{ height: "98vh" }}>
            <Col
              lg={6}
              md={6}
              className="h-100 d-flex justify-content-center"
              style={{ backgroundColor: "#F4F7FF" }}
            >
              <div className="text-enter d-flex flex-column justify-content-center align-items-center ">
                <img src={vectorImg} alt="" />
                <h3 className="mt-1 mb-5 pb-1">{CONST.GOD}</h3>
                <img src={loginLogo} alt="logo" />
              </div>
            </Col>
            <Col
              lg={6}
              md={6}
              className="h-100"
              style={{ backgroundColor: "#FFFFFF" }}
            >
              <div className="text-enter d-flex h-100 flex-column justify-content-center align-items-center">
                <div className="border h-50 w-50 shadow">
                  <div className="">
                    <div>
                      <h3 align="center">{CONST.RESET_PASSWORD}</h3>
                      <Formik
                        initialValues={{
                          password: "",
                          confirm_password: "",
                        }}
                        validationSchema={resetPasswordSchema}
                        onSubmit={handleSubmit}
                      >
                        {({ errors, touched, values, handleChange }) => (
                          <Form name="" id="" className="mt-4">
                            <div>
                              <label
                                htmlFor="user_pass reset_pass"
                                className="mt-4"
                              >
                                {CONST.NEW_PASSWORD}
                                <br />
                                <AuthInput
                                  type="password"
                                  name="password"
                                  id="user_pass"
                                  className="form-control"
                                  size="20"
                                  isPassword
                                  isIcon
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                />
                              </label>
                            </div>

                            <div>
                              <label htmlFor="user_pass2" className="mt-2">
                                {CONST.CONFIRM_NEW_PASSWORD}
                                <br />
                                <AuthInput
                                  type="password"
                                  name="confirm_password"
                                  id="user_pass2"
                                  className="input"
                                  size="20"
                                  isPassword
                                  isIcon
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                />
                              </label>
                            </div>
                            <p className="submit mt-4 d-flex justify-content-center">
                              <input
                                type="submit"
                                name="wp-submit"
                                id="wp-submit"
                                className="btn "
                                value="Save"
                                onSubmit={(values) => handleSubmit(values)}
                              />
                            </p>
                          </Form>
                        )}
                      </Formik>
                      <div>
                        {error && (
                          <span className="text-danger d-flex justify-content-center">
                            {errorMessage}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {ExpiredLinkModal(
            linkExpired,
            linkExpiredToggle,
            CONST.PASSWORD_EXPIRED,
            CONST.PASSWORD_EXPIRED_MSG
          )}
          {MessageModal(modal, toggle, CONST.PASSWORD_UPDATED, CONST.LOG_IN)}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
