export const DevURL = "https://api-staging.aequas.sg/api/agency/";
// export const DevURL = "http://localhost:1234/api/agency/";

// 3004
// ? Authentication URL
export const LOGIN_URL = "login";
export const REGISTER_URL = "register";
export const FORGOT_PASSWORD = "forgot_password";
export const RESET_PASSWORD = "reset_password";
export const CHANGE_PASSWORD = "change_password";
export const VERIFICATION = "verify";
export const MY_PROFILE = "profile";

export const URL = DevURL;
