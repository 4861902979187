import moment from "moment";
import React from "react";
import "font-awesome/css/font-awesome.min.css";

const OnShiftRow = ({ jobData }) => {
  return (
    <React.Fragment>
      <td>{jobData.jobId}</td>
      <td>
        <span style={{ fontWeight: 700 }}>{jobData.description}</span>
        <br />
        <span>{jobData.streetName}</span>
      </td>
      <td>
        <span style={{ fontWeight: 700 }}>{`${jobData.fullName}`}</span>
        <br />
        <span>{`+${jobData.countryCode} ${jobData.phoneNumber}`}</span>
      </td>
      <td>{moment.utc(jobData.dateTime).format("MMMM DD YYYY hh:mm a")}</td>
      <td className="text-center">
        <div style={{ fontSize: "1.5rem" }}>
          {jobData.startTime ? (
            <i
              className="fa fa-check-circle"
              aria-hidden="true"
              style={{ color: "#009900" }}
            ></i>
          ) : (
            <i
              className="fa fa-exclamation-triangle "
              aria-hidden="true"
              style={{ color: "#EB7E19" }}
            ></i>
          )}
        </div>
      </td>
      <td className="text-center">
        <div style={{ fontSize: "1.5rem" }}>
          {jobData.startLocationCheck ? (
            <i
              className="fa fa-check-circle"
              aria-hidden="true"
              style={{ color: "#009900" }}
            ></i>
          ) : (
            <i
              className="fa fa-exclamation-triangle"
              aria-hidden="true"
              style={{ color: "#EB7E19" }}
            ></i>
          )}
        </div>
      </td>
    </React.Fragment>
  );
};
export default OnShiftRow;
