import React from "react";

import { Button, Modal, ModalBody } from "reactstrap";
import { constants as CONST } from "constants/constants";

import manwithmail from "../../../assets/img/manwithmail.png";

const EmailPopup = (modal, toggle, title, message) => {
  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <div className="popup">
          <img src={manwithmail} alt="logo" />
          <h3>{title}</h3>
          <br></br>
          <p>{message}</p>
          <Button
            color="yellow"
            style={{ width: "66px" }}
            onClick={toggle}
            className="btn"
          >
            {CONST.OK}
          </Button>{" "}
        </div>
      </ModalBody>

      {/* <Button color="secondary" onClick={this.toggle}>Cancel</Button> */}
    </Modal>
  );
};

export default EmailPopup;
