export const constants = {
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  REGISTER: "/register",
  FORGOT: "/forgot",
  JOB_DETAIL: "/job_detail",
  CREATE_JOB: "/job_post",
  JOBS: "/jobs",
  TRANSACTION: "/transaction",
};
