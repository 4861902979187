export const constants = {
  LOG_IN: "Log In",
  SIGN_UP: "Sign Up",
  PASSWORD: "Password",
  EMAIL: "Email",
  GOD: "Guards on Demand",
  FORGOT: "Forgot Password?",
  ACCESSTOKEN: "ACCESSTOKEN",
  AGENCY: "Agency Name",
  CONFIRM_PASSWORD: "Confirm Password",
  ALREADY_LOGIN: "Click here to Login",
  ALREADY_ACCOUNT: "Have an account?",
  RESET_PASSWORD: "Reset Password",
  RECOVERY_LINK: "Email me recovery link",
  EMAIL_VERIFICATION_SENT_TITLE: "Email Verification Sent",
  EMAIL_VERIFICATION_SENT_MESSAGE:
    "Check your email & click the link to activate your account. ",
  EMAIL_SENT_TITLE: "Email has been sent",
  EMAIL_SENT_MESSAGE:
    "Check your email & click the link to reset your password",
  FULL_NAME: "Full Name",
  PHONE_NUMBER: "Phone Number",
  CHANGE_PASSWORD: "Change Password",
  LOG_OUT: "Log Out",
  USER_PROFILE: "User Profile",
  EDIT_PROFILE: "Edit Profile",
  EDIT: "Edit",
  SAVE: "Save",
  OK: "Ok",
  USER_PROGILE_MSG: "User profile updated",
  PASSWORD_MSG: "Password updated",
  OLD_PASSWORD: "Old Password",
  NEW_PASSWORD: "New Password",
  CONFIRM_NEW_PASSWORD: "Confirm New Password",
  UEN: "UEN",
  AGENCY_LICENSE: "Agency License",
  ADDRESS: "Address",
  LICENSE_EXPIRY: "License Expiry",
  LOGO: "Logo",
  AGENCY_PROFILE: "Agency Profile",
  AGENCY_PROFILE_DETAILS: "Agency Profile Details",
  EDIT_AGENCY_PROFILE: "Edit Agency Profile",
  PASSWORD_UPDATED: "Password Updated",
  CREATE_NEW_JOB: "Create New Job",
  VERIFICATION_SUCCESS_MESSAGE:
    "Account has been verified, you can log in and use your account.",
  ACCOUNT_VERIFICATION_SUCCESS: "Account verification success",
  ACCOUNT_VERIFICATION_FAILURE: "Account verification failed",
  VERIFICATION_FAILURE_MESSAGE:
    "Account has been not verified, you can contact admin",
  TOP_UP_SUCCESS: "Top up success",
  PRIVACY_POLICY: "Privacy Policy",
  PASSWORD_EXPIRED: "Your password reset link has expired",
  PASSWORD_EXPIRED_MSG: "Please request reset password link again.",
};
