import React, { useEffect, useRef, useState } from 'react'
import './style.css'
const NewEventModal = ({ fData, day }) => {
    const [dimensios, setDimensions] = useState(null)
    const eventRef = useRef()
    function tConvert(time) {
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) {
            time = time.slice(1);
            time[5] = +time[0] < 12 ? ' AM' : ' PM';
            time[0] = (+time[0] % 12).toString().padStart(2, "0") || "12";
        }
        return time.join('');
    }

    // const width = screen.availWidth
    useEffect(() => {
        setDimensions(eventRef.current.getBoundingClientRect())
    }, [])

    if (eventRef.current) {
        const screenWidth = window.screen.availWidth
        const positionX = eventRef.current.getBoundingClientRect().x
        const popupWidth = eventRef.current.getBoundingClientRect().width
        const screenHeight = window.screen.availHeight
        const positionY = eventRef.current.getBoundingClientRect().y
        const popupHeight = eventRef.current.getBoundingClientRect().height
        // console.log("width", screenWidth, positionX, popupWidth, screenHeight, positionY, popupHeight);
        const id = document.getElementById('newEventModal')
        if (screenWidth < (+positionX + +popupWidth)) {
            if (screenHeight < (+positionY + +popupHeight)) {
                id.classList.remove('posTopBottom')
                id.classList.add('posTopLeft')
            } else {
                id.classList.remove('posTopBottom')
                id.classList.add('posLeft')
            }
        } else if (screenHeight < (+positionY + +popupHeight)) {
            id.classList.remove('posTopBottom')
            id.classList.add('posTop')
        }
    }

    return (
        <>
            <div ref={eventRef} className="posTopBottom" id="newEventModal">
                <div className='event-date'>
                    <h2>{day.date.format("dddd, DD MMM YYYY")}</h2>
                </div>
                <div className='eventMain'>
                    {
                        fData.map((item) => {
                            return (
                                <div className='eventData'>
                                    <div className='time'>
                                        <p className='starttime'>{tConvert(item["jobStartTime"].split("T")[1].split(":00.00")[0])}</p>
                                        <p className='endtime'>{tConvert(item["jobEndTime"].split("T")[1].split(":00.00")[0])}</p>
                                    </div>
                                    <div className='eventDetail'>
                                        <h5 className='eventtitle'>{item.description}</h5>
                                        <p className='eventdescription'>{item.address}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                {/* <input
                    className={error ? 'error' : ''}
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    id="eventTitleInput"
                    placeholder="Event Title"
                /> */}
                {/* 
                <button
                    onClick={() => {
                        if (title) {
                            setError(false);
                            onSave(title);
                        } else {
                            setError(true);
                        }
                    }}
                    id="saveButton">Save</button>


                <button
                    onClick={onClose}
                    id="cancelButton">Cancel</button> */}
            </div>

            {/* <div id="modalBackDrop"></div> */}
        </>
    )
}

export default NewEventModal
