import api from "../api/api.js";
import * as url from "../api/authUrl.js";

// User Login API
export const userAuthLogin = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.LOGIN_URL, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const userAuthRegister = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.REGISTER_URL, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const userForgotPassword = (query) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.FORGOT_PASSWORD + `?email=${query}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const userResetPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.RESET_PASSWORD, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const userChangePassword = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.CHANGE_PASSWORD, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const AuthVerifyEmail = (paydata) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.VERIFICATION, paydata)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetMyProfileAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.MY_PROFILE)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const UpdateMyProfileAPI = (paydata) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.MY_PROFILE, paydata)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
