import React, { useState, useEffect } from "react";
import { Row, Col, Button, CardBody } from "reactstrap";
import { constants as JOBCONST } from "../../../constants/jobDetails.js";
import { constants as BTN } from "../../../constants/buttonconstant.js";
import { jobStatus as JOBSTATUS } from "../../../constants/jobStatus.js";
import { constants as ROUTE_CONST } from "constants/routes";
import { useHistory } from "react-router-dom";
import { pwmRank } from "constants/rank.js";
import License from "./License.jsx";
import EnterNewAmount from "./EnterNewAmount.jsx";
import CancelJob from "./CancelJob.jsx";
import { getJobById, updateJobStatus } from "../../../services/Job.js";
import moment from "moment";
import queryString from "query-string";

const style = { color: "#909CAC" };
const JobDetail = (props) => {
  const [jobStatus, setJobStatus] = useState();
  const [modal, setModal] = useState(false);
  const [newAmountModal, setNewAmountModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [job, setJob] = useState({});
  const [guard, setGuard] = useState({});
  const [result, setResult] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const history = useHistory();

  //API CALL
  useEffect(() => {
    (() => {
      const query = queryString.parse(props.location.search);
      if (query.id) {
        getJobById(query.id)
          .then((res) => {
            let jobDetailData = res.data.data.data.jobDetailData;
            let guardDetailData = res.data.data.data.guardDetailData;
            setJob(jobDetailData);
            setGuard(guardDetailData);
            setJobStatus(jobDetailData.jobStatus);
          })
          .catch((err) => {
            const errorMessage =
              err && err.data && err.data.error && err.data.error.messsage;
            setError(true);
            if (!err.data) {
              setErrorMsg("Network Error");
            } else {
              setErrorMsg(errorMessage);
            }
          });
      } else {
        history.push(`${ROUTE_CONST.JOBS}`);
      }
    })();
  }, [result]);
  //
  const toggle = () => setModal(!modal);
  const toggle1 = () => setNewAmountModal(!newAmountModal);
  const toggle2 = () => setCancelModal(!cancelModal);
  const updateStatus = () => setResult(!result);
  const markAsCompleJob = () => {
    if (jobStatus === BTN.PENDING_DISPUTE_RESOLUTION) {
      setNewAmountModal(!newAmountModal);
    } else if (jobStatus === BTN.PENDING_VERIFICATION) {
      const paydata = { jobStatus: BTN.COMPLETED };
      updateJobStatus(job.id, paydata)
        .then((res) => {
          console.log(res);
          setResult(!result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  let diffHours = moment(new Date()).diff(new Date(job.createdAt), "hours");
  const diputeFun = () => {
    const paydata = { jobStatus: "PENDING_DISPUTE_RESOLUTION" };
    updateJobStatus(job.id, paydata)
      .then((res) => {
        console.log(res);
        setResult(!result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const completedFun = () => setResult(!result);

  return (
    <div>
      <div className="content">
        <Row>
          <div className="page-title">
            <div className="float-left">
              <h1 className="title ml-3 font-weight-bold">
                {JOBCONST.JOB_DETAILS}
              </h1>
            </div>
          </div>
          <CardBody>
            <Col xs={12} md={12}>
              {jobStatus === BTN.CANCELED && (
                <section className="box" style={{ borderRadius: "8px" }}>
                  <div>
                    <h3 className="font-weight-bold p-3">
                      {JOBCONST.CANCELATION_REASON}
                    </h3>
                  </div>
                  <hr />
                  <div>
                    <p className="pb-3 pl-3" style={{ color: "#414248" }}>
                      {job.cancellationReason}
                    </p>
                  </div>
                </section>
              )}

              {!error && (
                <section className="box">
                  <div className="">
                    <div className="page-sub-title panel_header">
                      <div className="float-left d-flex">
                        <h1 className="title ml-3">{job.jobId}</h1>
                        <div
                          className={" ml-4 mt-3 jobStatus " + job.jobStatus}
                        >
                          {`${JOBSTATUS[jobStatus]}`}
                        </div>
                      </div>
                    </div>
                    <div className="ml-3 row h-20">
                      <p className="col-sm-4  text-lg " style={style}>
                        {JOBCONST.DESCREPTION}
                      </p>
                      <div className="col-sm-8 ">
                        <p style={{ color: "#414248" }}>{job.description}</p>
                      </div>
                    </div>
                    <div className="ml-3 row  h-20">
                      <p className="col-sm-4  text-lg " style={style}>
                        {JOBCONST.DATE_AND_TIME}
                      </p>
                      <div className="col-sm-8 ">
                        <p style={{ color: "#414248" }}>
                          {moment
                            .utc(job.dateTime)
                            .format("DD MMM, YYYY hh:mm A")}
                        </p>
                      </div>
                    </div>
                    <div className="ml-3 row  h-20">
                      <p className="col-sm-4  text-lg " style={style}>
                        {JOBCONST.DURATION}
                      </p>
                      <div className="col-sm-8 ">
                        <p style={{ color: "#414248" }}>{job.duration} hour</p>
                      </div>
                    </div>
                    <div className="ml-3 row  h-20">
                      <p className="col-sm-4  text-lg " style={style}>
                        {JOBCONST.RATE_PER_HOUR}
                      </p>
                      <div className="col-sm-8 ">
                        <p style={{ color: "#414248" }}>${job.rateHour}</p>
                      </div>
                    </div>
                    <div className="ml-3 row  h-20">
                      <p className="col-sm-4  text-lg " style={style}>
                        {JOBCONST.POSTAL_CODE}
                      </p>
                      <div className="col-sm-8 ">
                        <p style={{ color: "#414248" }}>{job.address || "-"}</p>
                      </div>
                    </div>
                    <div className="ml-3 row  h-20">
                      <p className="col-sm-4  text-lg " style={style}>
                        {JOBCONST.TYPE_OF_SITE}
                      </p>
                      <div className="col-sm-8 ">
                        <p style={{ color: "#414248" }}>{job.siteType}</p>
                      </div>
                    </div>
                    <div className="ml-3 row  h-20">
                      <p className="col-sm-4  text-lg " style={style}>
                        {JOBCONST.REQUIRED_RANK}
                      </p>
                      <div className="col-sm-8 ">
                        <p style={{ color: "#414248" }}>
                          {job.requiredRank
                            ? pwmRank[job.requiredRank.toUpperCase()]
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="ml-3 row h-20">
                      <p className="col-sm-4  text-lg " style={style}>
                        {JOBCONST.OTHER_INFORMATION}
                      </p>
                      <div className="col-sm-8 ">
                        <p style={{ color: "#414248" }}>
                          {job.otherInfo || "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  {guard && Object.keys(guard).length !== 0 && (
                    <div className="">
                      <div className="page-sub-title panel_header">
                        <div className="float-left">
                          <h1 className="ml-3">Guard Detail</h1>
                        </div>
                      </div>
                      <div className="ml-3 row h-20">
                        <p className="col-sm-4  text-lg " style={style}>
                          {JOBCONST.NAME}
                        </p>
                        <div className="col-sm-8 ">
                          <p style={{ color: "#414248" }}>{guard.fullname}</p>
                        </div>
                      </div>
                      <div className="ml-3 row  h-20">
                        <p className="col-sm-4  text-lg " style={style}>
                          {JOBCONST.LICENSE_ID}
                        </p>
                        <div className="col-sm-8 ">
                          <p style={{ color: "#414248" }}>
                            {guard.licenseId}{" "}
                            <a
                              href="#"
                              onClick={toggle}
                              className="ml-4"
                              style={{ color: "#4E79EE" }}
                            >
                              View License
                            </a>
                            <License
                              modal={modal}
                              toggle={toggle}
                              image={guard.licenseImage}
                            />
                          </p>
                        </div>
                      </div>
                      <div className="ml-3 row  h-20">
                        <p className="col-sm-4  text-lg " style={style}>
                          {JOBCONST.EXPIRY_ID}
                        </p>
                        <div className="col-sm-8 ">
                          <p style={{ color: "#414248" }}>
                            {moment(guard.licenseExpireDate).format(
                              "DD MMM, YYYY"
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="ml-3 row  h-20">
                        <p className="col-sm-4  text-lg " style={style}>
                          {JOBCONST.CONTACT}
                        </p>
                        <div className="col-sm-8 ">
                          <p style={{ color: "#414248" }}>
                            {guard.phoneNumber}
                          </p>
                        </div>
                      </div>
                      <div className="ml-3 row  h-20">
                        <p className="col-sm-4  text-lg " style={style}>
                          {JOBCONST.PMW_RANK}
                        </p>
                        <div className="col-sm-8 ">
                          <p style={{ color: "#414248" }}>
                            {guard.pwmRank
                              ? pwmRank[guard.pwmRank.toUpperCase()]
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="ml-3 row  h-20">
                        <p className="col-sm-4  text-lg " style={style}>
                          {JOBCONST.CLOCK_IN_TIME}
                        </p>
                        <div className="col-sm-8 ">
                          <p style={{ color: "#414248" }}>
                            {guard.startTime
                              ? moment
                                  .utc(guard.startTime)
                                  .format("DD MMM, YYYY hh:mm A")
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="ml-3 row  h-20">
                        <p className="col-sm-4  text-lg " style={style}>
                          {JOBCONST.CLOCK_OUT_TIME}
                        </p>
                        <div className="col-sm-8 ">
                          <p style={{ color: "#414248" }}>
                            {guard.endTime
                              ? moment
                                  .utc(guard.endTime)
                                  .format("DD MMM, YYYY hh:mm A")
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="ml-3 row h-20">
                        <p className="col-sm-4  text-lg " style={style}>
                          {JOBCONST.LOCATION_CHECK}
                        </p>
                        <div className="col-sm-8 ">
                          <p style={{ color: "#414248" }}>
                            {guard.startLocationCheck ? "yes" : "no"}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </section>
              )}
              {error && <span className="text-danger">{errorMsg}</span>}
              <div>
                <div className="float-right mr-0">
                  {jobStatus === BTN.PENDING_VERIFICATION ? (
                    <Button className="m-0 ml-3 dispute" onClick={diputeFun}>
                      {" "}
                      {BTN.DISPUTE_RESOLUTION}
                    </Button>
                  ) : (
                    ""
                  )}
                  {jobStatus === BTN.PENDING_VERIFICATION ||
                  jobStatus === BTN.PENDING_DISPUTE_RESOLUTION ? (
                    <Button
                      className="m-0 ml-3 complete"
                      onClick={markAsCompleJob}
                    >
                      {" "}
                      {BTN.MARK_AS_COMPLETED}
                    </Button>
                  ) : (
                    ""
                  )}
                  {jobStatus === BTN.NEW_JOB && diffHours < 24 ? (
                    <Button className="m-0 ml-3 dispute" onClick={toggle2}>
                      {" "}
                      {BTN.CANCEL}
                    </Button>
                  ) : (
                    ""
                  )}
                  <EnterNewAmount
                    modal={newAmountModal}
                    toggle={toggle1}
                    onSubmit={completedFun}
                    job={job}
                  />
                  <CancelJob
                    modal={cancelModal}
                    toggle={toggle2}
                    jobId={job.id}
                    status={updateStatus}
                  />
                </div>
              </div>
            </Col>
          </CardBody>
        </Row>
      </div>
    </div>
  );
};

export default JobDetail;
