import React from "react";
import Transaction from "views/general/Transaction/Transaction.jsx";
import AgencyProfile from "views/general/AgencyProfile/AgencyProfile.jsx";
import JobDetail from "views/general/Jobs/JobDetail.jsx";
import Dashboard from "views/general/Dashboard/Dashboard.jsx";
import Jobs from "views/general/Jobs/Jobs.jsx";
import NewJobPost from "views/general/Pages/NewJobPost.jsx";
import { constants as CONST } from "constants/constants";

const Home = () => {
  return (
    <svg
      id="sidebar-icon"
      width="18"
      height="20"
      viewBox="0 0 18 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.22919 17.6302H6.13544V11.1198H11.8646V17.6302H15.7709V7.47395L9.00002 2.39583L2.22919 7.47395V17.6302ZM2.22919 19.1927C1.79516 19.1927 1.42624 19.0408 1.12242 18.737C0.818597 18.4332 0.666687 18.0642 0.666687 17.6302V7.47395C0.666687 7.2309 0.723111 6.99652 0.835958 6.77083C0.948805 6.54513 1.10071 6.36284 1.29169 6.22395L8.06252 1.14583C8.20141 1.04166 8.34898 0.963537 8.50523 0.911453C8.66148 0.85937 8.82641 0.833328 9.00002 0.833328C9.17363 0.833328 9.33856 0.85937 9.49481 0.911453C9.65106 0.963537 9.79863 1.04166 9.93752 1.14583L16.7084 6.22395C16.8993 6.36284 17.0512 6.54513 17.1641 6.77083C17.2769 6.99652 17.3334 7.2309 17.3334 7.47395V17.6302C17.3334 18.0642 17.1814 18.4332 16.8776 18.737C16.5738 19.0408 16.2049 19.1927 15.7709 19.1927H10.3021V12.6823H7.69794V19.1927H2.22919Z" />
    </svg>
  );
};
const JobsIcon = () => {
  return (
    <svg
      id="sidebar-icon"
      width="18"
      height="17"
      viewBox="0 0 18 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.91669 16.5C1.58335 16.5 1.29169 16.375 1.04169 16.125C0.791687 15.875 0.666687 15.5833 0.666687 15.25V4.97916C0.666687 4.64583 0.791687 4.35416 1.04169 4.10416C1.29169 3.85416 1.58335 3.72916 1.91669 3.72916H5.87502V1.91666C5.87502 1.58333 6.00002 1.29166 6.25002 1.04166C6.50002 0.791664 6.79169 0.666664 7.12502 0.666664H10.875C11.2084 0.666664 11.5 0.791664 11.75 1.04166C12 1.29166 12.125 1.58333 12.125 1.91666V3.72916H16.0834C16.4167 3.72916 16.7084 3.85416 16.9584 4.10416C17.2084 4.35416 17.3334 4.64583 17.3334 4.97916V15.25C17.3334 15.5833 17.2084 15.875 16.9584 16.125C16.7084 16.375 16.4167 16.5 16.0834 16.5H1.91669ZM7.12502 3.72916H10.875V1.91666H7.12502V3.72916ZM1.91669 15.25H16.0834V4.97916H1.91669V15.25ZM1.91669 15.25V4.97916V15.25Z" />
    </svg>
  );
};
const Wallet = () => {
  return (
    <svg
      id="sidebar-icon"
      width="18"
      height="17"
      viewBox="0 0 18 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.3027 9.77384C12.6673 9.77384 12.9917 9.63167 13.2761 9.34733C13.5604 9.06299 13.7026 8.73126 13.7026 8.35214C13.7026 7.98761 13.5604 7.66681 13.2761 7.38976C12.9917 7.11271 12.6673 6.97419 12.3027 6.97419C11.9382 6.97419 11.6138 7.11271 11.3294 7.38976C11.0451 7.66681 10.9029 7.98761 10.9029 8.35214C10.9029 8.73126 11.0451 9.06299 11.3294 9.34733C11.6138 9.63167 11.9382 9.77384 12.3027 9.77384ZM1.97902 13.7765V14.9357V1.81234V13.7765ZM1.97902 16.248C1.64365 16.248 1.34108 16.1168 1.07132 15.8543C0.801566 15.5919 0.666687 15.2857 0.666687 14.9357V1.81234C0.666687 1.47696 0.801566 1.17439 1.07132 0.904637C1.34108 0.634879 1.64365 0.5 1.97902 0.5H15.1024C15.4523 0.5 15.7586 0.634879 16.021 0.904637C16.2835 1.17439 16.4147 1.47696 16.4147 1.81234V4.74322H15.1024V1.81234H1.97902V14.9357H15.1024V12.0267H16.4147V14.9357C16.4147 15.2857 16.2835 15.5919 16.021 15.8543C15.7586 16.1168 15.4523 16.248 15.1024 16.248H1.97902ZM9.80929 12.4641C9.31352 12.4641 8.91982 12.3183 8.62819 12.0267C8.33656 11.7351 8.19075 11.3486 8.19075 10.8675V5.90245C8.19075 5.40668 8.33656 5.01662 8.62819 4.73228C8.91982 4.44794 9.31352 4.30577 9.80929 4.30577H15.7148C16.2106 4.30577 16.6043 4.44794 16.8959 4.73228C17.1875 5.01662 17.3334 5.40668 17.3334 5.90245V10.8675C17.3334 11.3486 17.1875 11.7351 16.8959 12.0267C16.6043 12.3183 16.2106 12.4641 15.7148 12.4641H9.80929Z" />
    </svg>
  );
};
const Building = () => {
  return (
    <svg
      id="sidebar-icon"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.05558 17.3333C1.66977 17.3333 1.34184 17.1983 1.07178 16.9282C0.801718 16.6582 0.666687 16.3302 0.666687 15.9444V5.875C0.666687 5.4892 0.801718 5.16127 1.07178 4.8912C1.34184 4.62114 1.66977 4.48611 2.05558 4.48611H4.48613V2.05556C4.48613 1.66975 4.62116 1.34182 4.89122 1.07176C5.16129 0.801697 5.48922 0.666667 5.87502 0.666667H12.125C12.5108 0.666667 12.8388 0.801697 13.1088 1.07176C13.3789 1.34182 13.5139 1.66975 13.5139 2.05556V8.30556H15.9445C16.3303 8.30556 16.6582 8.44059 16.9283 8.71065C17.1983 8.98071 17.3334 9.30864 17.3334 9.69445V15.9444C17.3334 16.3302 17.1983 16.6582 16.9283 16.9282C16.6582 17.1983 16.3303 17.3333 15.9445 17.3333H10.2269V13.5139H7.77317V17.3333H2.05558ZM2.05558 15.9444H4.48613V13.5139H2.05558V15.9444ZM2.05558 12.125H4.48613V9.69445H2.05558V12.125ZM2.05558 8.30556H4.48613V5.875H2.05558V8.30556ZM5.87502 12.125H8.30558V9.69445H5.87502V12.125ZM5.87502 8.30556H8.30558V5.875H5.87502V8.30556ZM5.87502 4.48611H8.30558V2.05556H5.87502V4.48611ZM9.69447 12.125H12.125V9.69445H9.69447V12.125ZM9.69447 8.30556H12.125V5.875H9.69447V8.30556ZM9.69447 4.48611H12.125V2.05556H9.69447V4.48611ZM13.5139 15.9444H15.9445V13.5139H13.5139V15.9444ZM13.5139 12.125H15.9445V9.69445H13.5139V12.125Z" />
    </svg>
  );
};

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <Home />,
    component: Dashboard,
  },
  {
    path: "/jobs",
    name: "Jobs",
    icon: <JobsIcon />,
    component: Jobs,
  },
  {
    path: "/transaction",
    name: "Transaction",
    icon: <Wallet />,
    component: Transaction,
  },
  {
    path: "/agency_profile",
    name: "Agency Profile",
    icon: <Building />,
    component: AgencyProfile,
  },
  {
    path: "/jobs/job_detail",
    name: "JobDetail",
    component: JobDetail,
  },
  {
    path: "/jobs/job_post",
    name: "JobPost",
    component: NewJobPost,
  },
  {
    path: "/",
    redirect: true,
    pathTo: !localStorage.getItem(CONST.ACCESSTOKEN) ? "/login" : "/dashboard",
  },
];
export default dashRoutes;
