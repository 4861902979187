import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { getDashboardJob } from "services/Job.js";

const ShiftCount = (props) => {
  const params = { shift_status: props.shiftStatus };
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  useEffect(() => {
    getDashboardJob(params).then(
      (result) => {
        setIsLoaded(true);
        if (props.shiftStatus === "accepted") {
          const timeNow = moment(new Date());
          const next24Hour = moment(timeNow).add(1, "days");
          const upcomingJob = result.data.data.data.filter((job) => {
            const jobTime = moment(job.dateTime);
            if (jobTime.isBetween(timeNow, next24Hour)) {
              return job;
            }
          });
          setTotalRows(upcomingJob.length);
        } else {
          setTotalRows(result.data.data.count);
        }
      },
      (_error) => {
        setIsLoaded(true);
      }
    );
  }, []);

  return (
    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
      <Card>
        <CardBody>
          <span className="w-400">{props.title}</span>
          {!isLoaded && <div className="pl-3">Loading...</div>}
          {isLoaded && <h2 className="">{totalRows}</h2>}
        </CardBody>
      </Card>
    </div>
  );
};

export default ShiftCount;
