import api from "../api/api.js";
import * as url from "../api/jobUrl.js";

export const getJobById = (jobId) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.JOB_BY_ID + jobId)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const updateJobStatus = (jobId, paydata) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.UPDATE_JOBSTATUS + jobId, paydata)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const resolveDispute = (jobId, paydata) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.RESOLVE_DISPUTE + jobId, paydata)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getDashboardJob = (params) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithParamsToken(url.DASHBOARD_JOB, params)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const createJobPost = (payload) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.CREATE_JOB, payload)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getAllJobs = (params) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithParamsToken(url.GET_ALL_JOB, params)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
