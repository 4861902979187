import React from "react";
import { Button, Modal, ModalBody, Row } from "reactstrap";
import { constants as CONST } from "../../../constants/constants";
import Completed from "assets/img/Completed.png";
import { constants as ROUTE_CONST } from "../../../constants/routes.js";
import { Link } from "react-router-dom";

const VerificationSuccessModal = (props) => {
  const path = `${ROUTE_CONST.LOGIN}`;
  return (
    <Row id="dialguebox">
      <div>
        <div>
          <Modal
            isOpen={props.modal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ModalBody>
              <div className="popup">
                <img src={Completed} alt="logo" />
                <h3>{CONST.ACCOUNT_VERIFICATION_SUCCESS}</h3>
                <br></br>
                <p style={{ color: "#414248" }}>
                  {CONST.VERIFICATION_SUCCESS_MESSAGE}
                </p>
                <br></br>
                <Button color="yellow" className="btn" onClick={props.toggle}>
                  <Link
                    to={path}
                    style={{ textDecoration: "none", color: "#FFFFFF" }}
                  >
                    {CONST.LOG_IN}
                  </Link>
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </Row>
  );
};

export default VerificationSuccessModal;
