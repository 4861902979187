export const jobStatus = {
  TAKEN: "Taken",
  PENDING_VERIFICATION: "Pending Verification",
  PENDING_DISPUTE_RESOLUTION: "Pending Dispute Resolution",
  COMPLETED: "Completed",
  CANCEL: "Cancel",
  NEW: "New Job",
  CANCELLED: "Canceled",
  EXPIRED: "Expired",
};
