export const constants = {
TAKEN : "TAKEN",
PENDING_VERIFICATION:"PENDING_VERIFICATION",
DISPUTE_RESOLUTION:"Dispute Resolution",
MARK_AS_COMPLETED:"Mark As Completed",
OK:"OK",
PENDING_DISPUTE_RESOLUTION:"PENDING_DISPUTE_RESOLUTION",
COMPLETED:"COMPLETED",
CANCEL:"Cancel Job",
NEW_JOB:"NEW",
CANCELED:"CANCELLED",
EXPIRED:"EXPIRED"
}