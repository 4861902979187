import React from "react";

import { Button, Modal, ModalBody } from "reactstrap";
import { constants as CONST } from "constants/constants";

import time_expired from "../../../assets/img/Time_expired.png";

const ExpiredLinkModal = (modal, toggle, title, message) => {
  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <div className="popup">
          <img src={time_expired} alt="logo" />
          <h3>{title}</h3>
          <br></br>
          <p>{message}</p>
          <Button
            color="yellow"
            style={{ width: "66px" }}
            onClick={toggle}
            className="btn"
          >
            {CONST.OK}
          </Button>{" "}
        </div>
      </ModalBody>

      {/* <Button color="secondary" onClick={this.toggle}>Cancel</Button> */}
    </Modal>
  );
};

export default ExpiredLinkModal;