import Register from "../views/general/Pages/Register";
import Login from "../views/general/Pages/Login";
import ResetPassword from "../views/general/Pages/ResetPassword";
import Verification from "../views/general/Pages/Verification";
import PageUnSupport from "../views/general/Pages/PageUnSupport";

var indexRoutes = [
  {
    path: "/ResetPassword",
    name: "ResetPassword",
    component: ResetPassword,
  },
  { path: "/login", name: "Login", component: Login },
  { path: "/register", name: "Register", component: Register },
  {
    path: "/success",
    name: "success",
    component: Verification,
  },
  { path: "/failure", name: "failure", component: Verification },
  { path: "/unsupport", name: "unsupport", component: PageUnSupport },
];

export default indexRoutes;
