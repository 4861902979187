import React, { useEffect, useState } from "react";
import { CardBody, Table } from "reactstrap";
import { getDashboardJob } from "services/Job.js";
import { PaginationFunction } from "../../../components/PaginationFunction";
import AcceptedShiftRow from "./AcceptedShiftRow";
import EmptyView from "./EmptyView";
import { constants as ROUTE_CONST } from "constants/routes";
import { useHistory } from "react-router-dom";

const onShiftColumns = [
  {
    name: "Description",
    width: "20%",
  },
  {
    name: "Guard",
    width: "20%",
  },
  {
    name: "Shift start Time",
    width: "20%",
  },
];

function DashboardAcceptedShift(props) {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const perPage = 4;
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    fetchData(activePage, perPage);
  }, []);

  const fetchData = async (page, limit) => {
    const params = { page, limit, shift_status: "accepted", days: 1 };

    getDashboardJob(params).then(
      (result) => {
        setIsLoaded(true);
        setJobs(result.data.data.data);
        setTotalRows(result.data.data.count);
      },
      (err) => {
        const errorMessage =
          err && err.data && err.data.error && err.data.error.message;
        setIsLoaded(true);
        setError(true);
        if (!err.data) {
          setErrorMessage("Network Error");
        } else {
          setErrorMessage(errorMessage);
        }
      }
    );
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    fetchData(page, perPage);
  };

  const history = useHistory();
  const getJobDetailPage = (id) => {
    history.push(`${ROUTE_CONST.JOBS}${ROUTE_CONST.JOB_DETAIL}?id=${id}`);
  };

  if (error) {
    return <div className="text-danger pl-3">Error: {errorMessage}</div>;
  } else if (!isLoaded) {
    return <div className="pl-3">Loading...</div>;
  } else {
    return (
      <CardBody>
        <div className="bordered-table-box">
          <Table className="bordered_table dashboardAcceptedShift-table">
            <thead>
              <tr className="text-left" style={{ color: "#414248" }}>
                {onShiftColumns.map((column, index) => {
                  return (
                    <th style={{ width: column.width }} key={index}>
                      {" "}
                      {column.name}{" "}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {jobs.length === 0 && (
                <tr>
                  <td colSpan={25} className="dashboardAcceptedShift-table-td">
                    <EmptyView
                      title={"Sorry!"}
                      discription={"No Shift Found"}
                      bgcolor={"white"}
                      icon={"mdi mdi-account"}
                    ></EmptyView>
                  </td>
                </tr>
              )}
              {jobs.map((job, index) => {
                return (
                  <tr
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => getJobDetailPage(job.id)}
                  >
                    <AcceptedShiftRow jobData={job} />
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {jobs.length !== 0 ? (
            <div colSpan={12}>
              <div
                style={{
                  width: "fit-content",
                  display: "flex",
                  justifyItems: "flex-start",
                }}
              >
                <PaginationFunction
                  activePage={activePage}
                  totalCount={totalRows}
                  handlePageChange={handlePageChange}
                  pageDataCount={perPage}
                />
              </div>
            </div>
          ) : null}
        </div>
      </CardBody>
    );
  }
}

export default DashboardAcceptedShift;
