import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Modal, Label } from "reactstrap";
import { constants as CONST } from "../../constants/constants";
import { constants as ROUTE_CONST } from "../../constants/routes";
import ForgotPasswordModal from "./changePassword";
import EditProfileModal from "./EditUserProfile";

const UserProfileModal = (props) => {
  const [editProfile, setEditProfile] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const openEditProfileModal = () => {
    setEditProfile(!editProfile);
  };

  const handleChangePassword = () => {
    setChangePassword(!changePassword);
  };
  const history = useHistory();
  const handleLogOut = () => {
    props.handleOpenModal();
    localStorage.clear();
    history.push({ pathname: `${ROUTE_CONST.LOGIN}` });
  };

  return (
    <div>
      <Modal
        isOpen={props.modal}
        toggle={props.handleOpenModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "10px", maxWidth: "581px", maxHeight: "480px" }}
        className="w-100"
      >
        <div className="userprofile">
          <div>
            <h1 align="center">{CONST.USER_PROFILE}</h1>
          </div>
          <div className="userinfo">
            <div className="row">
              <Label htmlFor="" className="col-md-6">
                {CONST.FULL_NAME}
              </Label>
              <div className="col-md-6">
                <p>{props.fullName}</p>
              </div>
            </div>
            <div className="row">
              <Label htmlFor="" className="col-md-6">
                {CONST.EMAIL}
              </Label>
              <div className="col-md-6">
                <p>{localStorage.getItem(CONST.EMAIL)}</p>
              </div>
            </div>
            <div className="row">
              <Label htmlFor="" className="col-md-6">
                {CONST.PHONE_NUMBER}
              </Label>
              <div className="col-md-6">
                <p>
                  {props.phoneNumber
                    ? `+${props.countryCode} ${props.phoneNumber}`
                    : "-"}
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column align-items-center">
            <Button
              type="button"
              color="none"
              onClick={() => {
                props.handleOpenModal();
                openEditProfileModal();
              }}
              style={{ width: "77px" }}
              className="btn"
            >
              {CONST.EDIT}
            </Button>
            <Link
              to="#"
              onClick={() => { window.location.href = `https://www.aequas.sg/privacy-policy`}}
              title="Privacy Policy"
              className="mt-3 text-primary"
            >
              {CONST.PRIVACY_POLICY}
            </Link>
            <Link
              to="#"
              onClick={() => {
                props.handleOpenModal();
                handleChangePassword();
              }}
              title="Change Password"
              className="mt-3 text-primary"
            >
              {CONST.CHANGE_PASSWORD}
            </Link>
            <Link
              to="#"
              className="mt-3 text-danger"
              onClick={handleLogOut}
              title="Log Out"
            >
              {CONST.LOG_OUT}
            </Link>
          </div>
        </div>
      </Modal>
      {EditProfileModal(editProfile, openEditProfileModal, { ...props })}
      {ForgotPasswordModal(changePassword, handleChangePassword)}
    </div>
  );
};

export default UserProfileModal;
