import { Formik, Form } from "formik";
import React, { useState } from "react";
import { Button, Modal, Label } from "reactstrap";
import { userForgotPassword } from "services/Auth.js";
import AuthInput from "../../../components/Auth/AuthInput";
import * as Yup from "yup";
import { constants as ERROR_CONST } from "../../../constants/errorMessages.js";
import { constants as CONST } from "../../../constants/constants";
import EmailPopup from "./emailpopup";

const ForgotModal = (modal, openModal) => {
  const EmailSchema = Yup.object().shape({
    email: Yup.string()
      .email(ERROR_CONST.VALID_EMAIL)
      .required(ERROR_CONST.EMAIL_REQUIRED),
  });

  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const [popUpEmail, setPopUpEmail] = useState(false);
  const openEmailModal = () => {
    setPopUpEmail(!popUpEmail);
  };

  const handleSubmit = (values) => {
    const email = values.email.toLowerCase();
    setError(false);
    userForgotPassword(email)
      .then((res) => {
        const data = res && res.data && res.data.data;
        if (data) {
          openModal();
          setPopUpEmail(true);
        }
      })
      .catch((err) => {
        const errorMessage =
          err && err.data && err.data.error && err.data.error.message;
        setError(true);
        setMessage(errorMessage);
      });
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={openModal}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "10px", maxWidth: "570px", maxHeight: "304px" }}
      >
        <div className="forgotpassword">
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={EmailSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, handleChange }) => (
              <Form name="loginform">
                {/* <ModalBody> */}
                <div>
                  <div>
                    <h1 align="center">{CONST.RESET_PASSWORD}</h1>
                  </div>
                  <div>
                    <Label
                      htmlFor="backdrop1"
                      style={{
                        width: "100%",
                        textAlign: "left",
                        display: "block",
                        marginBottom: "5px",
                      }}
                    >
                      {CONST.EMAIL}
                      <br />
                      <AuthInput
                        type="text"
                        name="email"
                        id="forgotemail"
                        placeholder="Insert email"
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                        values={values}
                      />
                    </Label>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      type="submit"
                      color="none"
                      onSubmit={(values) => handleSubmit(values)}
                      className={`btn ${
                        values.email !== undefined && values.email.trim() !== ""
                          ? "btn-color"
                          : "btn-accent"
                      } mt-4`}
                    >
                      {CONST.RECOVERY_LINK}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <div>{error && <span className="text-danger">{message}</span>}</div>
        </div>
      </Modal>
      {EmailPopup(
        popUpEmail,
        openEmailModal,
        CONST.EMAIL_SENT_TITLE,
        CONST.EMAIL_SENT_MESSAGE
      )}
    </div>
  );
};

export default ForgotModal;
