import { Formik, Form } from "formik";
import React, { useState } from "react";
import { Button, Modal, Label } from "reactstrap";
import { userChangePassword } from "services/Auth.js";
import AuthInput from "components/Auth/AuthInput";
import * as Yup from "yup";
import { constants as ERROR_CONST } from "../../constants/errorMessages.js";
import { constants as CONST } from "../../constants/constants";
import MessageModal from "./MessageModal";

const ForgotPasswordModal = (modal, openModal) => {
  const ForgotPasswordSchema = Yup.object().shape({
    old_password: Yup.string()
      .required(ERROR_CONST.PASSWORD_REQUIRED)
      .min(8, ERROR_CONST.PASSWORD_LENGTH),
    new_password: Yup.string()
      .required(ERROR_CONST.PASSWORD_REQUIRED)
      .min(8, ERROR_CONST.PASSWORD_LENGTH),
    confirm_password: Yup.string()
      .required(ERROR_CONST.PASSWORD_REQUIRED)
      .min(8, ERROR_CONST.PASSWORD_LENGTH)
      .oneOf([Yup.ref("new_password"), null], ERROR_CONST.PASSWORD_MATCH),
  });

  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const [popUpMessage, setPopUpMessage] = useState(false);
  const openMessageModal = () => {
    setPopUpMessage(!popUpMessage);
  };

  const handleSubmit = (values) => {
    const data = {
      email: localStorage.getItem(CONST.EMAIL),
      password: values.old_password,
      new_password: values.new_password,
    };
    setError(false);
    userChangePassword(data)
      .then((res) => {
        const data = res && res.data && res.data.data;
        if (data) {
          openModal();
          openMessageModal();
        } else {
          setError(true);
          setMessage(data.message);
        }
      })
      .catch((err) => {
        const errorMessage =
          err && err.data && err.data.error && err.data.error.message;
        setError(true);
        setMessage(errorMessage);
      });
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={openModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "10px", maxWidth: "581px" }}
      >
        <div className="changepassword">
          <Formik
            initialValues={{
              old_password: "",
              new_password: "",
              confirm_password: "",
            }}
            validationSchema={ForgotPasswordSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, handleChange }) => (
              <Form>
                {/* <ModalBody> */}
                <div className="">
                  <div>
                    <h1 align="center">{CONST.CHANGE_PASSWORD}</h1>
                  </div>
                  <div>
                    <Label
                      htmlFor="backdrop1"
                      style={{
                        width: "100%",
                        textAlign: "left",
                        display: "block",
                        marginBottom: "20px",
                        color: "#626469",
                      }}
                    >
                      {CONST.OLD_PASSWORD}
                      <br />
                      <AuthInput
                        type="password"
                        name="old_password"
                        id="oldpassword"
                        placeholder="Insert old password"
                        errors={errors}
                        handleChange={handleChange}
                        isPassword
                        isIcon
                        touched={touched}
                        values={values}
                      />
                    </Label>
                  </div>

                  <div>
                    <Label
                      htmlFor="backdrop1"
                      style={{
                        width: "100%",
                        textAlign: "left",
                        display: "block",
                        marginBottom: "20px",
                        color: "#626469",
                      }}
                    >
                      {CONST.NEW_PASSWORD}
                      <br />
                      <AuthInput
                        type="password"
                        name="new_password"
                        id="newpassword"
                        placeholder="Insert new password"
                        errors={errors}
                        handleChange={handleChange}
                        isPassword
                        isIcon
                        touched={touched}
                        values={values}
                      />
                    </Label>
                  </div>
                  <div>
                    <Label
                      htmlFor="backdrop1"
                      style={{
                        width: "100%",
                        textAlign: "left",
                        display: "block",
                        marginBottom: "20px",
                        color: "#626469",
                      }}
                    >
                      {CONST.CONFIRM_NEW_PASSWORD}
                      <br />
                      <AuthInput
                        type="password"
                        name="confirm_password"
                        id="confirmpassword"
                        placeholder="Insert new password"
                        errors={errors}
                        handleChange={handleChange}
                        isPassword
                        isIcon
                        touched={touched}
                        values={values}
                      />
                    </Label>
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <Button
                      type="submit"
                      color="none"
                      style={{ width: "82px" }}
                      onSubmit={(values) => handleSubmit(values)}
                      className="btn"
                    >
                      {CONST.SAVE}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <div>{error && <span>{message}</span>}</div>
        </div>
      </Modal>
      {MessageModal(popUpMessage, openMessageModal, CONST.PASSWORD_MSG)}
    </div>
  );
};

export default ForgotPasswordModal;
