import React, { useState } from "react";
import { Input } from "reactstrap";
import "font-awesome/css/font-awesome.min.css";
function AuthInput({
  type = "text",
  values,
  name,
  placeholder,
  errors,
  touched,
  isIcon,
  isPassword,
  handleChange,
  disabledField = false,
}) {
  const isError = errors[name] && touched[name];
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={isIcon ? "has-wrapper input-icons" : "has-wrapper"}>
      {isPassword && showPassword && (
        <span>
          <i
            className="fa fa-eye-slash icon"
            onClick={() => setShowPassword(false)}
          ></i>
        </span>
      )}

      {isPassword && !showPassword && (
        <span>
          <i
            className="fa fa-eye icon"
            onClick={() => setShowPassword(true)}
          ></i>
        </span>
      )}
      <Input
        value={values[name]}
        placeholder={placeholder}
        name={name}
        type={showPassword ? "text" : type}
        onChange={handleChange}
        disabled={disabledField}
        className={isPassword ? "icon-input-field" : ""}
      />

      {isError && (
        <span style={{ fontSize: 12 }} className="text-left mt-1 text-danger">
          {errors[name]}
        </span>
      )}
    </div>
  );
}

export default AuthInput;
