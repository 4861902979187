import React, { useRef, useEffect } from "react";
import { Input } from "reactstrap";
const AutoComplete = ({ setAddress }) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: "sg" },
    fields: ["formatted_address", "name"],
    types: ["establishment"],
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    window.google.maps.event.addListener(
      autoCompleteRef.current,
      "place_changed",
      () => {
        setAddress(autoCompleteRef.current.getPlace().formatted_address);
      }
    );
  }, []);
  return (
    <div>
      <Input innerRef={inputRef} autoComplete="on" />
    </div>
  );
};
export default AutoComplete;
