import React, { useState } from "react";
import { Col, Button, Modal, ModalBody, Input } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { updateJobStatus } from "../../../services/Job.js";
import { constants as JOBCONST } from "../../../constants/jobDetails.js";

const CancelJob = (props) => {
  const cancelJobSchema = Yup.object().shape({
    cancel_reason: Yup.string().required("please enter reason"),
  });

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleOnSubmit = (values) => {
    const paydata = {
      ...values,
      jobStatus: "CANCELLED",
    };
    updateJobStatus(props.jobId, paydata)
      .then((res) => {
        console.log(res);
        props.toggle();
        props.status();
      })
      .catch((err) => {
        const errorMessage =
          err && err.data && err.data.error && err.data.error.messsage;
        setError(true);
        setErrorMsg(errorMessage);
      });
  };

  return (
    <Modal
      isOpen={props.modal}
      fade={false}
      toggle={props.toggle}
      //   size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      //   style={{ height: "300px" }}
    >
      <ModalBody className="d-flex flex-column align-items-center rounded">
        <Col lg={12} md={12}>
          <div className="content d-flex flex-column align-items-center">
            <h1 className="font-weight-bold">{JOBCONST.CANCELATION_REASON}</h1>
            <br />
            <div style={{ width: "100%" }}>
              <label>Please insert reason of job cancellation.</label>
              <Formik
                initialValues={{
                  cancel_reason: "",
                }}
                validationSchema={cancelJobSchema}
                onSubmit={handleOnSubmit}
              >
                {({ errors, touched, values, handleChange }) => (
                  <Form
                    name=""
                    id=""
                    className="d-flex flex-column align-items-center"
                  >
                    <Input
                      type="textarea"
                      name="cancel_reason"
                      className="form-control"
                      style={{ resize: "none" }}
                      placeholder="Insert reason here."
                      value={values.cancel_reason}
                      onChange={handleChange}
                    />
                    {errors.cancel_reason && (
                      <span
                        style={{ fontSize: 13 }}
                        className="text-left mt-1 text-danger"
                      >
                        {errors.cancel_reason}
                      </span>
                    )}
                    {!errors.cancel_reason && <br />}
                    {error && <span className="text-danger">{errorMsg}</span>}
                    <br />
                    <Button
                      type="submit"
                      className={`btn ${
                        values.cancel_reason !== undefined &&
                        values.cancel_reason.trim() !== ""
                          ? "btn-color"
                          : "btn-accent"
                      } mt-4`}
                      value="done"
                      style={{ minwidth: "200px" }}
                      onSubmit={(values) => {
                        handleOnSubmit(values);
                      }}
                    >
                      Submit
                    </Button>
                    {/* </form> */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Col>
      </ModalBody>
    </Modal>
  );
};

export default CancelJob;
