import React, { useState } from "react";
import { Button, Modal, Row } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import payNow from "assets/img/payNow.png";
import AuthInput from "../Auth/AuthInput";
import { payNowPaymentRrequest } from "services/Transaction.js";

const style = {
  color: "black",
  textDecoration: "underline",
  position: "absolute",
  top: "0%",
  right: "0%",
};

const TransactionModal = (props) => {
  const TransactionSchema = Yup.object().shape({
    amount: Yup.number("input must be number")
      .min(100, "Mininum amount must be $100")
      .max(5000, "Maximum amount must be $5000")
      .required("Amount is required"),
  });

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const handleSubmit = (values) => {
    const path = window.location.pathname;
    const params = {
      amount: values.amount,
      path,
    };
    payNowPaymentRrequest(params)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setError(false);
        window.location.replace(data.url);
      })
      .catch((err) => {
        setError(true);
        if (!err.data) {
          setErrorMsg("Network Error");
        } else {
          setErrorMsg(err.data.error.message);
        }
      });
  };

  return (
    <Row id="dialguebox">
      <div>
        <div>
          <Modal
            isOpen={props.modal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="w-100"
            style={{
              maxWidth: "350px",
            }}
          >
            <div>
              <div className="">
                <h5 align="center" className="mr-3">
                  AEQUAS PTE. LTD.
                </h5>
                <a
                  href="#"
                  className="ml-4 mr-2"
                  style={style}
                  onClick={props.toggle}
                >
                  close
                </a>
              </div>
              <hr />
              <Formik
                initialValues={{
                  amount: "",
                }}
                validationSchema={TransactionSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, values, handleChange }) => (
                  <Form name="loginform">
                    {/* <ModalBody> */}
                    <div>
                      <div className="m-4">
                        <span>Amount</span>
                        <br />
                        <span>SGD</span>

                        <br />
                        <AuthInput
                          type="number"
                          name="amount"
                          placeholder="Insert amount"
                          errors={errors}
                          handleChange={handleChange}
                          touched={touched}
                          values={values}
                        />
                        {error && (
                          <span className="text-danger">{errorMsg}</span>
                        )}
                      </div>
                      <div className="m-4">
                        <p>Select Payment Method</p>

                        <Button
                          type="submit"
                          style={{
                            backgroundColor: "#FFFFFF",
                            width: "100%",
                            height: "60px",
                          }}
                          onSubmit={(values) => handleSubmit(values)}
                        >
                          <img src={payNow} alt="" height="35px" />
                        </Button>
                        <div style={{ height: "300px" }}></div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <p align="center">Powered by Hitpay</p>
          </Modal>
        </div>
      </div>
    </Row>
  );
};

export default TransactionModal;
