export const constants = {
    DESCREPTION:"Description",
    DATE_AND_TIME:"Date and Time",
    DURATION:"Duration",
    RATE_PER_HOUR:"Rate per hour",
    POSTAL_CODE:"Postal Code",
    TYPE_OF_SITE:"Type of Site",
    REQUIRED_RANK:"Required Rank",
    OTHER_INFORMATION:"Other Information",
    NAME:"Name",
    LICENSE_ID:"License ID",
    EXPIRY_ID:"Expiry ID",
    CONTACT:"Contact",
    PMW_RANK:"PMW Rank",
    CLOCK_IN_TIME:"Clock in time",
    CLOCK_OUT_TIME:"Clock out time",
    LOCATION_CHECK:"Location Check",
    JOB_DETAILS:"Job details",
    CANCELATION_REASON:"Cancellation Reason",
}