import moment from "moment";
import React from "react";

function GetJob({ jobData, statusFunction }) {
  return (
    <React.Fragment>
      <td>{jobData.jobId}</td>
      <td>{jobData.description}</td>
      <td>{jobData.streetName}</td>
      <td>{moment.utc(jobData.jobStartTime).format("DD MMMM YYYY,hh:mm A")}</td>
      <td>{jobData.duration} hours</td>
      <td>{statusFunction(jobData.jobStatus)}</td>
    </React.Fragment>
  );
}

export default GetJob;
