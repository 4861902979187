import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Nav } from "reactstrap";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import dashboardlogo from "assets/img/dashboardlogo.png";
import { GetMyProfileAPI } from "services/Auth.js";
import UserProfileModal from "./UserProfileModal";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.state = {
      opendd: "",
      openmenu: "none",
      profileimg: "/images/AvatarPlaceholder-User.png",
      agencyName: "",
      fullName: "",
      countryCode: "",
      phoneNumber: "",
      email: "",
      openModal: false,
    };
    this.handleOpendd = this.handleOpendd.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
  }

  handleOpendd(open) {
    if (this.state.opendd === open) {
      this.setState({
        opendd: "",
      });
    } else {
      this.setState({
        opendd: open,
      });
    }
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1
      ? " active"
      : "";
  }

  handleOpenModal() {
    this.setState({ openModal: !this.state.openModal });
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    GetMyProfileAPI()
      .then((res) => {
        const data = res.data.data.data;
        this.setState({
          email: data.email,
          countryCode: data.countryCode,
          phoneNumber: data.phoneNumber,
          fullName: data.fullName,
          agencyName: data.agencyName,
        });
      })
      .catch((err) => {
        console.log("err======", err);
      });
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    return (
      <div className="sidebar">
        <div className="sidebar-wrapper" ref="sidebar">
          <div className="logo">
            <a href="/">
              <div className="logo-img row mt-4">
                <img src={dashboardlogo} alt="logo" className="light-logo" />
              </div>
            </a>
          </div>
          <Nav className="navigation">
            {this.props.routes.map((prop, key) => {
              if (prop.redirect) return null;
              if (!prop.icon) return null;
              return (
                <li
                  className={this.activeRoute(prop.path) + " nav-parent "}
                  key={key}
                  onClick={() => this.handleOpendd(prop.name)}
                >
                  <NavLink
                    to={prop.path}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <div className="d-flex align-items-center ">
                      {prop.icon}
                      <p className="ml-3"> {prop.name}</p>
                    </div>
                  </NavLink>
                </li>
              );
            })}
          </Nav>
          <div className="profile-info row">
            <div className="profile-image col-4">
              <Link to="#" onClick={this.handleOpenModal}>
                <img
                  alt="profile"
                  src={this.state.profileimg}
                  className="img-fluid avatar-image"
                />
              </Link>
            </div>
            <div className="profile-details col-8">
              <h3>
                {this.state.fullName}
                <span className="profile-status online"></span>
              </h3>
              <p className="profile-title">{this.state.agencyName}</p>
            </div>
          </div>
        </div>
        <UserProfileModal
          fullName={this.state.fullName}
          phoneNumber={this.state.phoneNumber}
          countryCode={this.state.countryCode}
          email={this.state.email}
          modal={this.state.openModal}
          handleOpenModal={this.handleOpenModal}
        />
      </div>
    );
  }
}

export default Sidebar;
