import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { constants as CONST } from "constants/constants";

const license = (props) => {
  return (
    <Modal
      isOpen={props.modal}
      fade={false}
      toggle={props.toggle}
      //   size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ height: "300px" }}
    >
      <ModalBody className="d-flex flex-column align-items-center rounded">
        <h1>Guard License</h1>
        <img src={props.image} alt="hjhj" height="300px" width="300px" />
        <br />
        <Button
          color="primary"
          onClick={props.toggle}
          style={{ height: "50px", width: "70px", backgroundColor: "#4E79EE" }}
        >
          {CONST.OK}
        </Button>{" "}
      </ModalBody>
    </Modal>
  );
};

export default license;
