import React, { useEffect, useState } from "react";
import { Row, Col, Label, Input } from "reactstrap";
import "font-awesome/css/font-awesome.min.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import * as Yup from "yup";
import { constants as CONST } from "constants/constants.js";
import { constants as ERR_CONST } from "constants/errorMessages";
import { constants as ROUTE_CONST } from "constants/routes";
import { pwmRank } from "../../../constants/rank";
import { Formik, Form, Field } from "formik";
import { createJobPost } from "../../../services/Job";
import CreditBalance from "../../../components/Transaction/CreditBalance";
import AutoComplete from "../../../components/AutoCompletePlace";
import { useHistory } from "react-router-dom";
import { getWalletDetails } from "services/Transaction";
import JobPostedModal from "../Jobs/JobPostedModal";
import queryString from "query-string";
import TopUpSuccess from "../../../components/Transaction/TopUpSuccess";

const initialValues = {
  description: "",
  siteType: "",
  duration: "",
  rateHour: "",
  requiredRank: "",
  otherInfo: "",
  time: "",
};

const NewJobPost = (props) => {
  const [startDate, setStartDate] = useState(moment());
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [address, setAddress] = useState("");
  const [credit, setCredit] = useState(0.0);
  const [modal, setModal] = useState(false);
  const [topUp, setTopUp] = useState(false);
  const [jobId, setJobId] = useState("");
  const [otherFieldValue, setOtherFieldValue] = useState("");
  const [radioSelect, setRadioSelect] = useState("");
  const [otherInput, setOtherInput] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const topUpToggle = () => {
    setTopUp(!topUp);
  };

  useEffect(() => {
    const query = queryString.parse(props.location.search);
    if (query.reference && query.status === "completed") {
      topUpToggle();
    }
    fetchData();
  }, []);

  const fetchData = () => {
    getWalletDetails().then((res) => {
      setCredit(res.data.data.data.credit);
    });
  };

  let [formValues, setFormValues] = useState(initialValues);

  const CreateJobSchema = Yup.object().shape({
    description: Yup.string().required(ERR_CONST.DESCRIPTION_REQUIRED),
    siteType: Yup.string().required(ERR_CONST.SITE_TYPE_REQUIRED),
    time: Yup.string()
      .required(ERR_CONST.TIME_REQUIRED)
      .test("name", ERR_CONST.JOB_POST_TIME, (value) => {
        return moment(
          moment(startDate).format("YYYY-MM-DD") + " " + value
        ).isBefore(moment(new Date()).format("YYYY-MM-DD hh:mm A"))
          ? false
          : true;
      }),
    duration: Yup.number()
      .typeError(ERR_CONST.DURATION_NUMBER)
      .min(1, ERR_CONST.DURATION_MIMINUM)
      .required(ERR_CONST.DURATION_REQUIRED),
    rateHour: Yup.number()
      .typeError(ERR_CONST.RATE_HOUR_NUMBER)
      .required(ERR_CONST.RATE_HOUR_REQUIRED)
      .test(
        "rate",
        " Your balance is insufficient. Please top up to continue job posting.",
        (value) => {
          return Number(value) * Number(formValues.duration) > credit
            ? false
            : true;
        }
      ),
    requiredRank: Yup.string().required(ERR_CONST.REQUIRED_RANK_REQUIRED),
    otherInfo: Yup.string(),
  });
  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const otherRadioClicked = (e) => {
    if (e.target.value === "Other") {
      setOtherInput(true);
    }
  };

  useEffect(() => {
    if (radioSelect !== "Other") {
      setOtherInput(false);
    } else {
      setOtherInput(true);
    }
  }, [radioSelect]);

  const history = useHistory();
  const handleSubmit = (values) => {
    const data = {
      description: values.description,
      address,
      siteType: values.siteType,
      dateTime: `${moment(startDate).format("YYYY-MM-DD")} ${values.time}`,
      duration: values.duration,
      rateHour: values.rateHour * 1.1,
      requiredRank: values.requiredRank,
      otherInfo: values.otherInfo,
    };
    setError(false);
    createJobPost(data)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setJobId(data);
        toggle();
      })
      .catch((err) => {
        const errorMessage =
          err && err.data && err.data.error && err.data.error.message;
        setError(true);
        setErrorMessage(errorMessage);
      });
  };

  return (
    <div>
      <div className="content">
        <Row className="">
          <div className="page-title ml-3">
            <div className="float-left">
              <h1 className="title">{CONST.CREATE_NEW_JOB}</h1>
            </div>
          </div>
          <Col xs={12} md={8}>
            <div className="row margin-0">
              <div className="col-12">
                <section className="box rounded ">
                  <header className="panel_header">
                    <h2 className="title float-left">Job Detail</h2>
                  </header>
                  <div style={{ paddingLeft: "16px" }}>
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-12">
                      <Formik
                        initialValues={{
                          description: "",
                          siteType: "",
                          duration: "",
                          rateHour: "",
                          requiredRank: "",
                          otherInfo: "",
                          time: "",
                        }}
                        validationSchema={CreateJobSchema}
                        onSubmit={handleSubmit}
                      >
                        {(props) => {
                          const {
                            values,
                            touched,
                            errors,
                            handleChange,
                            setFieldValue,
                            handleBlur,
                            setErrors,
                          } = props;
                          const onChange = (e) => {
                            const targetEl = e.target;
                            const fieldName = targetEl.name;
                            setFormValues({
                              ...formValues,
                              [fieldName]: targetEl.value,
                            });
                            return handleChange(e);
                          };
                          return (
                            <Form>
                              <div className="form-row">
                                <div className="form-group col-md-12">
                                  <label htmlFor="inputname4">
                                    Description*
                                  </label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    name="description"
                                    id="des123"
                                    placeholder="Night Patrolling, Personal Guard, etc"
                                    onChange={onChange}
                                    value={values["description"]}
                                  />
                                  {errors["description"] &&
                                    touched["description"] && (
                                      <span
                                        style={{ fontSize: 12 }}
                                        className="text-left mt-1 text-danger"
                                      >
                                        {errors["description"]}
                                      </span>
                                    )}
                                </div>
                                <div className="form-group col-md-12">
                                  <label htmlFor="inputname4">Location*</label>
                                  <AutoComplete setAddress={setAddress} />
                                </div>
                                <div className="form-group col-md-12">
                                  <label htmlFor="inputname4">
                                    Type of Site*
                                  </label>
                                  <div>
                                    <label className="text-gray-500 font-bold">
                                      <Field
                                        name="siteType"
                                        value="Office Building"
                                        className="mr-2 leading-tight"
                                        type="radio"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "siteType",
                                            e.target.value
                                          );
                                          setRadioSelect(e.target.value);
                                        }}
                                      />
                                      <span
                                        className="text-sm"
                                        style={{ color: "#000000" }}
                                      >
                                        Office Building
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label className="text-gray-500 font-bold">
                                      <Field
                                        name="siteType"
                                        value="Shopping Mall"
                                        className="mr-2 leading-tight"
                                        type="radio"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "siteType",
                                            e.target.value
                                          );
                                          setRadioSelect(e.target.value);
                                        }}
                                      />
                                      <span
                                        className="text-sm"
                                        style={{ color: "#000000" }}
                                      >
                                        Shopping Mall
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label className="text-gray-500 font-bold">
                                      <Field
                                        name="siteType"
                                        value="Factory"
                                        className="mr-2 leading-tight"
                                        type="radio"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "siteType",
                                            e.target.value
                                          );
                                          setRadioSelect(e.target.value);
                                        }}
                                      />
                                      <span
                                        className="text-sm"
                                        style={{ color: "#000000" }}
                                      >
                                        Factory
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label className="text-gray-500 font-bold">
                                      <Field
                                        name="siteType"
                                        value="School"
                                        className="mr-2 leading-tight"
                                        type="radio"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "siteType",
                                            e.target.value
                                          );
                                          setRadioSelect(e.target.value);
                                        }}
                                      />
                                      <span
                                        className="text-sm"
                                        style={{ color: "#000000" }}
                                      >
                                        School
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label className="text-gray-500 font-bold">
                                      <Field
                                        name="siteType"
                                        value="Condominium"
                                        className="mr-2 leading-tight"
                                        type="radio"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "siteType",
                                            e.target.value
                                          );
                                          setRadioSelect(e.target.value);
                                        }}
                                      />
                                      <span
                                        className="text-sm"
                                        style={{ color: "#000000" }}
                                      >
                                        Condominium
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <label className="text-gray-500 font-bold">
                                      <Field
                                        name="siteType"
                                        value="Other"
                                        className="mr-2 leading-tight"
                                        type="radio"
                                        checked={
                                          radioSelect === "Other" ? true : false
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            "siteType",
                                            e.target.value
                                          );
                                          setRadioSelect(e.target.value);
                                          otherRadioClicked(e);
                                        }}
                                      />
                                      <span
                                        className="text-sm"
                                        style={{ color: "#000000" }}
                                      >
                                        Other
                                      </span>
                                    </label>
                                  </div>
                                  {otherInput && (
                                    <div>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="siteType"
                                        id="otherSite"
                                        placeholder=""
                                        onChange={(e) => {
                                          handleChange(e);
                                          setOtherFieldValue(e.target.value);
                                        }}
                                        value={otherFieldValue}
                                      />
                                    </div>
                                  )}
                                  {errors["siteType"] &&
                                    touched["siteType"] && (
                                      <span
                                        style={{ fontSize: 12 }}
                                        className="text-left mt-1 text-danger"
                                      >
                                        {errors["siteType"]}
                                      </span>
                                    )}
                                </div>
                                <Row className="form-group tableRow col-md-12 ">
                                  <Col mg={6} className="form-date">
                                    <label>Date* </label>
                                    <DatePicker
                                      selected={startDate}
                                      minDate={new Date()}
                                      dateFormat="DD MMMM YYYY"
                                      onChange={handleDateChange}
                                      className="form-control"
                                      name="dateTime"
                                      value={moment(startDate).format(
                                        "DD MMMM YYYY"
                                      )}
                                    />
                                    {errors["dateTime"] &&
                                      touched["dateTime"] && (
                                        <span
                                          style={{ fontSize: 12 }}
                                          className="text-left mt-1 text-danger"
                                        >
                                          {errors["dateTime"]}
                                        </span>
                                      )}
                                  </Col>
                                  <Col mg={6} className="form-time">
                                    <label>Time* </label>
                                    <Input
                                      type="time"
                                      value={values["time"]}
                                      onChange={onChange}
                                      onBlur={handleBlur}
                                      name="time"
                                    />
                                    {errors["time"] && touched["time"] && (
                                      <span
                                        style={{ fontSize: 12 }}
                                        className="text-left mt-1 text-danger"
                                      >
                                        {errors["time"]}
                                      </span>
                                    )}
                                  </Col>
                                </Row>

                                <Row className="form-group tableRow col-md-12 ">
                                  <Col mg={6} className="form-date">
                                    <label>Duration(hour)* </label>
                                    <Input
                                      type="select"
                                      name="duration"
                                      id="select_duration"
                                      onChange={onChange}
                                      value={values["duration"]}
                                    >
                                      <option>0</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                      <option value="6">6</option>
                                      <option value="7">7</option>
                                      <option value="8">8</option>
                                      <option value="9">9</option>
                                      <option value="10">10</option>
                                      <option value="11">11</option>
                                      <option value="12">12</option>
                                    </Input>
                                    {errors["duration"] &&
                                      touched["duration"] && (
                                        <span
                                          style={{ fontSize: 12 }}
                                          className="text-left mt-1 text-danger"
                                        >
                                          {errors["duration"]}
                                        </span>
                                      )}
                                  </Col>
                                  <Col mg={6} className="form-time">
                                    <label>Rate per hour* </label>
                                    <div className={`input-icons`}>
                                      <i className="fa fa-dollar icon" />
                                      <Input
                                        className="icon-input-field"
                                        style={{
                                          borderColor: `${
                                            errors.rateHour && touched.rateHour
                                              ? "red"
                                              : ""
                                          }`,
                                          margin: 0,
                                        }}
                                        placeholder="Insert rate per hour"
                                        type="text"
                                        name="rateHour"
                                        onChange={onChange}
                                        value={values["rateHour"]}
                                        onBlur={handleBlur}
                                      />
                                    </div>

                                    {errors["rateHour"] &&
                                      touched["rateHour"] && (
                                        <span
                                          style={{ fontSize: 12 }}
                                          className="text-left mt-1 text-danger"
                                        >
                                          {errors["rateHour"]}
                                        </span>
                                      )}
                                  </Col>
                                </Row>

                                <div className="form-group col-md-12">
                                  <Label htmlFor="exampleSelect">
                                    Required Rank*
                                  </Label>
                                  <Input
                                    type="select"
                                    name="requiredRank"
                                    id="exampleSelect"
                                    onChange={onChange}
                                    value={values["requiredRank"]}
                                  >
                                    <option hidden>Choose one</option>
                                    <option value="security_officer">
                                      {pwmRank.SECURITY_OFFICER}
                                    </option>
                                    <option value="senior_security_officer">
                                      {pwmRank.SENIOR_SECURITY_OFFICER}
                                    </option>
                                    <option value="security_supervisor">
                                      {pwmRank.SECURITY_SUPERVISOR}
                                    </option>
                                    <option value="senior_security_supervisor">
                                      {pwmRank.SENIOR_SECURITY_SUPERVISOR}
                                    </option>
                                    <option value="chief_security_officer">
                                      {pwmRank.CHIEF_SECURITY_OFFICER}
                                    </option>
                                  </Input>
                                  {errors["requiredRank"] &&
                                    touched["requiredRank"] && (
                                      <span
                                        style={{ fontSize: 12 }}
                                        className="text-left mt-1 text-danger"
                                      >
                                        {errors["requiredRank"]}
                                      </span>
                                    )}
                                </div>

                                <div className="form-group col-md-12">
                                  <Label htmlFor="exampleText">
                                    Other Information
                                  </Label>
                                  <Input
                                    type="textarea"
                                    name="otherInfo"
                                    id="exampleText"
                                    onChange={onChange}
                                    value={values["otherInfo"]}
                                  />
                                </div>
                                <div
                                  className="d-flex align-items-center mt-3"
                                  style={{
                                    backgroundColor: "#EEEEEE",
                                    borderRadius: "8px",
                                  }}
                                >
                                  <i className="fa fa-exclamation-circle p-3" />
                                  <span
                                    className="m-1"
                                    style={{ color: "#414248" }}
                                  >
                                    By clicking Confirm, you agree on the terms
                                    of condition of this service and deduction
                                    of the above stated Total credits.
                                  </span>
                                </div>
                              </div>
                              {error && (
                                <div className="d-flex flex-col justify-content-end">
                                  <span className="text-danger">
                                    {errorMessage}
                                  </span>
                                </div>
                              )}
                              <div className="d-flex flex-col justify-content-end mt-3 pb-3">
                                <span
                                  onClick={() => {
                                    history.push("/jobs");
                                  }}
                                  className="btn btn-default"
                                >
                                  Cancel
                                </span>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Confirm Posting
                                </button>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <CreditBalance credit={credit} />
            <Row
              className="p-4 rounded mt-3 d-flex flex-column"
              style={{ backgroundColor: "#FFFFFF" }}
            >
              <p
                style={{
                  color: "#414248",
                  fontSize: "20px",
                  fontWeight: "700",
                }}
              >
                Job Posting Preview
              </p>
              <div className="mt-3">
                <p>Description</p>
                <p
                  style={{
                    color: "#414248",
                  }}
                >
                  {formValues.description ? formValues.description : "-"}
                </p>
                <p>Location</p>
                <p
                  style={{
                    color: "#414248",
                  }}
                >
                  {address.length !== 0 ? address : "-"}
                </p>
                <p>Type of Site</p>
                <p
                  style={{
                    color: "#414248",
                  }}
                >
                  {radioSelect === "Other"
                    ? otherFieldValue || "-"
                    : radioSelect || "-"}
                </p>
                <p>Date and Time</p>
                <p
                  style={{
                    color: "#414248",
                  }}
                >
                  {startDate && formValues.duration && formValues.time
                    ? `${moment(
                        moment(startDate).format("YYYY-MM-DD") +
                          " " +
                          formValues.time
                      ).format("DD MMM, YYYY hh:mm A")} - ${moment(
                        moment(startDate).format("YYYY-MM-DD") +
                          " " +
                          formValues.time
                      )
                        .add(parseInt(formValues.duration), "hours")
                        .format("DD MMM, YYYY hh:mm A")} (${
                        formValues.duration
                      } hours)`
                    : "-"}
                </p>
                <p>Rate per hour</p>
                <p
                  style={{
                    color: "#414248",
                  }}
                >
                  {formValues.rateHour
                    ? new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(formValues.rateHour)
                    : "-"}
                </p>
                <p>Amount to Guard</p>
                <p
                  style={{
                    color: "#414248",
                  }}
                >
                  {formValues.rateHour &&
                  formValues.duration &&
                  !isNaN(formValues.rateHour)
                    ? new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(formValues.rateHour * formValues.duration)
                    : "-"}
                </p>
                <p>Amount to be deducted</p>
                <p
                  style={{
                    color: "#414248",
                  }}
                >
                  {formValues.rateHour &&
                  formValues.duration &&
                  !isNaN(formValues.rateHour)
                    ? new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(formValues.rateHour * 1.1 * formValues.duration)
                    : "-"}
                </p>
                <p>Required Rank</p>
                <p
                  style={{
                    color: "#414248",
                  }}
                >
                  {formValues.requiredRank
                    ? pwmRank[formValues.requiredRank.toUpperCase()]
                    : "-"}
                </p>
                <p>Other Information</p>
                <p
                  style={{
                    color: "#414248",
                  }}
                >
                  {formValues.otherInfo ? formValues.otherInfo : "-"}
                </p>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
      {jobId.length !== 0 && JobPostedModal(modal, toggle, jobId)}
      {TopUpSuccess(
        topUp,
        topUpToggle,
        `${ROUTE_CONST.JOBS}${ROUTE_CONST.CREATE_JOB}`
      )}
    </div>
  );
};

export default NewJobPost;
