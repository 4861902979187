export const constants = {
  VALID_EMAIL: "Enter valid email address",
  EMAIL_REQUIRED: "Please enter email address",
  ADDRESS_REQUIRED: "Please enter address",
  PASSWORD_REQUIRED: "Please enter password",
  CONFIRM_PASSWORD_REQUIRED: "Please enter confirm password",
  PASSWORD_LENGTH: "Password must be at least 8 character",
  AGENCY_NAME: "Agency name required",
  SPACE_NOT_ALLOWED: "Space is not allowed",
  PASSWORD_MATCH: "Password must be same",
  COUNTRYCODE_REQUIRED: "Country code required",
  PHONE_REQUIRED: "Phone number required",
  VALID_PHONE: "Enter valid phone number",
  VALID_COUNTRYCODE: "Enter valid country code",
  FULLNAME_REQUIRED: "Full name required",
  PROFILE_NOT_UPDATED: "Sorry Your Data is not updated",
  DATE_REUIRED: "Please Select valid date",
  LICENSE_EXPIRY_REQUIRED: "License Expiry Date must be later than Today",
  DESCRIPTION_REQUIRED: "Description is required",
  SITE_TYPE_REQUIRED: "Type of site is required",
  TIME_REQUIRED: "Job time is required",
  DURATION_REQUIRED: "Duration is required",
  RATE_HOUR_REQUIRED: "Rate per hour is required",
  REQUIRED_RANK_REQUIRED: "Required rank is required",
  RATE_HOUR_NUMBER: "Rate per hour must be number",
  DURATION_NUMBER: "Duration must be number",
  DURATION_MIMINUM: "Duration must be minium 1 hour",
  JOB_POST_TIME: "Job posting of past time is not allowed"
};
