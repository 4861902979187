import api from "../api/api.js";
import * as url from "../api/transactionUrl.js";

export const getTransactionList = (params) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithParamsToken(url.GET_TRANSACTION_LIST, params)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getWalletDetails = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_WALLET_DETAILS)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const payNowPaymentRrequest = (params) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithParamsToken(`${url.PAYMENT_REQUEST}`, params)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
