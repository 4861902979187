import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import moment from "moment";
import { constants as CONST } from "../../../constants/constants.js";
import { GetMyProfileAPI } from "../../../services/Auth.js";
import EditAgencyProfile from "../../../components/AgencyProfile/EditAgencyProfile";

const AgencyProfile = () => {
  const [inputValues, setInputValues] = useState({});
  const [active, setActive] = useState(false);
  const EditProfile = () => setActive(!active);

  useEffect(() => {
    (async () => {
      const data = await GetMyProfileAPI();
      setInputValues(data.data.data.data);
    })();
  }, [active]);

  return (
    <div>
      <div className="content">
        {!active && (
          <div>
            <Row>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title ml-3">{CONST.AGENCY_PROFILE}</h1>
                </div>
              </div>
              <Col xs={12} md={12}>
                <div className="col-12">
                  <section className="box ">
                    <header className="panel_header ">
                      <div className="page-sub-title">
                        <h1 className=" float-left ">
                          {CONST.AGENCY_PROFILE_DETAILS}
                        </h1>
                        <button
                          className="button float-right mt-4"
                          id="editbutton"
                          onClick={EditProfile}
                        >
                          Edit
                        </button>
                      </div>
                    </header>
                    <div className="content-body">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                          <div className="form-group row h-20">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-4 col-form-label"
                            >
                              {CONST.LOGO}
                            </label>
                            <div className="col-sm-8">
                              <img
                                src={
                                  inputValues.profileImage
                                    ? inputValues.profileImage
                                    : "/images/AvatarPlaceholder-Agency.png"
                                }
                                alt="profileImage here"
                                width="100px"
                                height="100px"
                                className="border"
                              />
                            </div>
                          </div>
                          <div className="form-group row h-20">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-4 col-form-label"
                            >
                              {CONST.AGENCY}
                            </label>
                            <div className="col-sm-8">
                              <p style={{ color: "#414248" }}>
                                {inputValues.agencyName}
                              </p>
                            </div>
                          </div>
                          <div className="form-group row h-20">
                            <label
                              htmlFor="inputPassword3"
                              className="col-sm-4 col-form-label"
                            >
                              {CONST.ADDRESS}
                            </label>
                            <div className="col-sm-8">
                              <p style={{ color: "#414248" }}>
                                {inputValues.address
                                  ? inputValues.address
                                  : "-"}
                              </p>
                            </div>
                          </div>
                          <div className="form-group row h-20">
                            <label
                              htmlFor="inputPassword3"
                              className="col-sm-4 col-form-label"
                            >
                              {CONST.UEN}
                            </label>
                            <div className="col-sm-8">
                              <p style={{ color: "#414248" }}>
                                {inputValues.uen ? inputValues.uen : "-"}
                              </p>
                            </div>
                          </div>
                          <div className="form-group row h-20">
                            <label
                              htmlFor="inputPassword3"
                              className="col-sm-4 col-form-label"
                            >
                              {CONST.AGENCY_LICENSE}
                            </label>
                            <div className="col-sm-8">
                              <p style={{ color: "#414248" }}>
                                {inputValues.agencyLicense
                                  ? inputValues.agencyLicense
                                  : "-"}
                              </p>
                            </div>
                          </div>
                          <div className="form-group row primary">
                            <label
                              htmlFor="inputPassword3"
                              className="col-sm-4 col-form-label"
                            >
                              {CONST.LICENSE_EXPIRY}
                            </label>
                            <div className="col-sm-8">
                              <p style={{ color: "#414248" }}>
                                {inputValues.licenseExpireDate
                                  ? moment(
                                      inputValues.licenseExpireDate
                                    ).format("DD MMMM YYYY")
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        )}
        {active && (
          <EditAgencyProfile data={inputValues} onSubmit={EditProfile} />
        )}
      </div>
    </div>
  );
};

export default AgencyProfile;
