import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
/*import 'font-awesome/css/font-awesome.min.css';*/
import "assets/scss/zest-admin.css";
import "assets/fonts/simple-line-icons.css";

import indexRoutes from "./routes/index.jsx";
import GeneralLayout from "./layouts/General";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      {window.innerWidth < 720 && hist.push("/unsupport")}
      {indexRoutes.map((prop, key) => {
        return (
          <Route exact path={prop.path} key={key} component={prop.component} />
        );
      })}
      <Route path="/" component={GeneralLayout} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
