import React from "react";
import {} from "reactstrap";
import logoGod from "assets/img/logoGod.png";
import deviceUnSupport from "assets/img/Devices-unsupport.png";

class PageUnSupport extends React.Component {
  render() {
    return (
      <div>
        <div
          className="container-fluid"
          style={{ maxWidth: 960, background: "#F4F7FF" }}
        >
          <div className="d-flex flex-column align-items-center">
            <div>
              <img
                src={logoGod}
                alt="logo"
                className="mb-4"
                style={{ marginTop: 32 }}
              />
            </div>
            <div style={{ marginTop: 56 }}>
              <img
                src={deviceUnSupport}
                alt="logo"
                className="mb-4"
                //   style={{ marginTop: 150 }}
              />
            </div>
            <div style={{ width: 216 }}>
              <p
                style={{
                  color: "#000000",
                  fontFamily: "Barlow",
                  fontSize: 24,
                  fontWeight: 700,
                }}
              >
                Unsupported Device
              </p>
            </div>
            <div>
              <p style={{ color: "#414248", marginTop: 16 }}>
                Please access this app from your desktop or tablet.
              </p>
            </div>

            <div style={{ marginTop: 48 }}>
              <button
                className="btn"
                onClick={() =>
                  (window.location.href = `https://www.aequas.sg/`)
                }
              >
                Go to AEQUAS homepage
              </button>
            </div>
            <div style={{ marginTop: 36, marginBottom: 20 }}>
              <span>© 2022 Guards on Demand</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageUnSupport;
