import React, { useState, useEffect } from "react";
import { Row, Button } from "reactstrap";
import { getWalletDetails } from "../../services/Transaction";
import TransactionModal from "./TransactionModal";

const CreditBalance = (props) => {
  const [credit, setCredit] = useState(0.0);
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    fetchData();
  }, [props.credit]);

  const fetchData = () => {
    getWalletDetails().then((res) => {
      setCredit(res.data.data.data.credit);
    });
  };
  return (
    <Row xs={12} md={12}>
      <div className="db_box icon_stats d-flex align-items-center">
        {props.image && (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="widicon float-left m-0 mr-3"
          >
            <path
              d="M26.6051 23.5477C27.3342 23.5477 27.9831 23.2633 28.5517 22.6947C29.1204 22.126 29.4048 21.4625 29.4048 20.7043C29.4048 19.9752 29.1204 19.3336 28.5517 18.7795C27.9831 18.2254 27.3342 17.9484 26.6051 17.9484C25.876 17.9484 25.2271 18.2254 24.6585 18.7795C24.0898 19.3336 23.8055 19.9752 23.8055 20.7043C23.8055 21.4625 24.0898 22.126 24.6585 22.6947C25.2271 23.2633 25.876 23.5477 26.6051 23.5477ZM5.95768 31.5529V33.8714V7.62467V31.5529ZM5.95768 36.4961C5.28693 36.4961 4.6818 36.2336 4.14228 35.7087C3.60277 35.1837 3.33301 34.5713 3.33301 33.8714V7.62467C3.33301 6.95392 3.60277 6.34879 4.14228 5.80927C4.6818 5.26976 5.28693 5 5.95768 5H32.2044C32.9043 5 33.5167 5.26976 34.0417 5.80927C34.5666 6.34879 34.8291 6.95392 34.8291 7.62467V13.4864H32.2044V7.62467H5.95768V33.8714H32.2044V28.0534H34.8291V33.8714C34.8291 34.5713 34.5666 35.1837 34.0417 35.7087C33.5167 36.2336 32.9043 36.4961 32.2044 36.4961H5.95768ZM21.6182 28.9283C20.6267 28.9283 19.8393 28.6366 19.256 28.0534C18.6728 27.4701 18.3811 26.6973 18.3811 25.7349V15.8049C18.3811 14.8134 18.6728 14.0332 19.256 13.4646C19.8393 12.8959 20.6267 12.6115 21.6182 12.6115H33.4293C34.4208 12.6115 35.2082 12.8959 35.7915 13.4646C36.3747 14.0332 36.6663 14.8134 36.6663 15.8049V25.7349C36.6663 26.6973 36.3747 27.4701 35.7915 28.0534C35.2082 28.6366 34.4208 28.9283 33.4293 28.9283H21.6182Z"
              fill="#4E79EE"
            />
          </svg>
        )}
        <div className="stats float-left m-0">
          <span className="widtag">Credit Balance</span>
          <h2 className="widtitle">${credit}</h2>
        </div>
        <Button
          color="yellow"
          className="m-0 pl-4 pr-4 pt-2 pb-2"
          style={{ minWidth: "100px" }}
          onClick={toggle}
        >
          top up
        </Button>
      </div>
      <TransactionModal modal={modal} toggle={toggle} />
    </Row>
  );
};

export default CreditBalance;
