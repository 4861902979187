import moment from 'moment';
import React from 'react'
import NewEventModal from './NewEventModal';
import './style.css'
const Day = ({ day, onClick, clicked, setClicked, eventForDate, items }) => {


    const toDateCovert = (date) => date ? date.toDate() : ""
    const date = new Date(toDateCovert(day.date).toString()).getDate()
    const months = new Date(toDateCovert(day.date).toString()).getMonth() + 1
    const year = new Date(toDateCovert(day.date).toString()).getFullYear()
    const dateString = `${year}-${months}-${date.toString().padStart(2, "0")}`
    const fData = items.length > 0 ? items.filter(item => item["jobStartTime"].split("T")[0] === dateString) : []

    const toDaydate = new Date();
    // console.log(toDaydate, "toDaydate");

    function tConvert(time) {
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) {
            time = time.slice(1);

            time[5] = +time[0] < 12 ? 'AM' : 'PM';
            time[0] = (+time[0] % 12).toString().padStart(2, "0") || "12";
        }
        return time.join('');
    }

    const utc = moment(new Date);

    const sortedData = arr => {
        return arr.length ? arr.sort((a, b) => {
            const timeA = a["jobStartTime"].split("T")[1].split(":00.00")[0]
            const timeB = b["jobStartTime"].split("T")[1].split(":00.00")[0]
            return (timeA.split(":")[0] * 60 + +timeA.split(":")[1]) - (timeB.split(":")[0] * 60 + +timeB.split(":")[1])
        }) : []
    }
    // console.log("fData", sortedData(fData));


    return (
        <div>
            <div onClick={onClick} className={`day ${(utc.format("YYYY-MM-DD") === day.date.format("YYYY-MM-DD") ? 'current' : '')} ${(!day.isCurrentMonth ? 'padding' : '')} `}>
                {day.currDate}
                <ul onClick={() => setClicked(null)}>
                    {sortedData(fData).slice(0, 5).map(item => {
                        return (
                            <div className='event-list'>
                                <li className='event-desc'>{item.description}</li>
                                <li className='event-time'>{tConvert(item["jobStartTime"].split("T")[1].split(":00.00")[0])}</li>
                            </div>
                        )
                    })}
                </ul>
                {
                    fData.length > 5 &&
                    <>
                        <div className='event-more'>{fData.length > 5 && fData.length - 5} More</div>
                    </>
                }
                {
                    clicked && toDateCovert(clicked).toString() === toDateCovert(day.date).toString() && fData.length > 0 &&
                    <NewEventModal fData={sortedData(fData)} day={day} />
                }
            </div>
        </div>
    )
}

export default Day
