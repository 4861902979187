import React from "react";
import { Button, Modal, ModalBody, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { constants as CONST } from "../../constants/constants";
import Completed from "assets/img/Completed.png";

const MessageModal = (modal, openModal, message, buttonName) => {
  const history = useHistory();
  return (
    <Row id="dialguebox">
      <div>
        <div>
          <Modal
            isOpen={modal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ModalBody>
              <div className="editpopup">
                <img src={Completed} alt="logo" />
                <br></br>
                <h3>{message}</h3>
                <br></br>
                <Button
                  color="yellow"
                  style={{ width: "66px" }}
                  className="btn"
                  onClick={() => {
                    openModal();
                    history.go("/");
                  }}
                >
                  {buttonName ? buttonName : CONST.OK}
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </Row>
  );
};

export default MessageModal;
