import React from "react";
import { Button, Modal, ModalBody, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { constants as CONST } from "constants/constants";
import { constants as ROUTE_CONST } from "constants/routes";
import Completed from "assets/img/Completed.png";

const JobPostedModal = (modal, openModal, id) => {
  const history = useHistory();
  return (
    <Row id="dialguebox">
      <div>
        <div>
          <Modal
            isOpen={modal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            shouldCloseOnOverlayClick={false}
          >
            <ModalBody>
              <div className="editpopup">
                <img src={Completed} alt="logo" />
                <br></br>
                <h3>Job posted successfully</h3>
                <br></br>
                <Button
                  className="btn"
                  onClick={() => {
                    openModal();
                    history.push(
                      `${ROUTE_CONST.JOBS}${ROUTE_CONST.JOB_DETAIL}?id=${id}`
                    );
                  }}
                >
                  {CONST.OK}
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </Row>
  );
};

export default JobPostedModal;
