import { Formik, Form } from "formik";
import React, { useState } from "react";
import { Button, Modal, Label, Input, Row, Col } from "reactstrap";
import { UpdateMyProfileAPI } from "services/Auth.js";
import AuthInput from "../Auth/AuthInput";
import * as Yup from "yup";
import { constants as ERROR_CONST } from "constants/errorMessages.js";
import { constants as CONST } from "constants/constants";
import MessageModal from "./MessageModal";

const EditProfileModal = (modal, openModal, props) => {
  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const countryCodeRegExp = /^(\+?\d{1,3}|\d{1,4})$/;
  const ProfileSchema = Yup.object().shape({
    fullName: Yup.string()
      .matches(/^[^\s]+(\s+[^\s]+)*$/, ERROR_CONST.SPACE_NOT_ALLOWED)
      .required(ERROR_CONST.FULLNAME_REQUIRED),
    countryCode: Yup.string()
      .matches(countryCodeRegExp, ERROR_CONST.VALID_COUNTRYCODE)
      .required(ERROR_CONST.COUNTRYCODE_REQUIRED),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, ERROR_CONST.VALID_PHONE)
      .required(ERROR_CONST.PHONE_REQUIRED),
  });

  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  const [popUpMessage, setPopUpMessage] = useState(false);
  const openMessageModal = () => {
    setPopUpMessage(!popUpMessage);
  };

  const handleSubmit = (values) => {
    const data = {
      fullName: values.fullName,
      countryCode: values.countryCode,
      phoneNumber: values.phoneNumber,
    };
    setError(false);
    UpdateMyProfileAPI(data)
      .then((res) => {
        const data = res && res.data && res.data.data;
        console.log("=====", data);
        openModal();
        openMessageModal();
      })
      .catch((err) => {
        const errorMessage =
          err && err.data && err.data.error && err.data.error.message;
        setError(true);
        setMessage(errorMessage);
      });
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={openModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "10px", maxWidth: "581px" }}
      >
        <div className="editprofile">
          <Formik
            initialValues={{
              fullName: props.fullName ? props.fullName : "",
              countryCode: props.countryCode ? props.countryCode : "",
              phoneNumber: props.phoneNumber ? props.phoneNumber : "",
            }}
            validationSchema={ProfileSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, handleChange }) => (
              <Form>
                {/* <ModalBody> */}
                <div>
                  <div>
                    <h1 align="center">{CONST.EDIT_PROFILE}</h1>
                  </div>
                  <div>
                    <Label
                      htmlFor="backdrop1"
                      style={{
                        width: "100%",
                        textAlign: "left",
                        display: "block",
                        marginBottom: "20px",
                        color: "#626469",
                      }}
                    >
                      {CONST.FULL_NAME}
                      <br />
                      <AuthInput
                        type="text"
                        name="fullName"
                        id="fullname"
                        placeholder="Insert Full Name"
                        errors={errors}
                        handleChange={handleChange}
                        touched={touched}
                        values={values}
                      />
                    </Label>
                  </div>
                  <div>
                    <Label
                      htmlFor="backdrop1"
                      style={{
                        width: "100%",
                        textAlign: "left",
                        display: "block",
                        marginBottom: "20px",
                        color: "#626469",
                      }}
                    >
                      {CONST.EMAIL}
                      <br />
                      <Input
                        type="text"
                        name="email"
                        id="fullname"
                        placeholder={localStorage.getItem(CONST.EMAIL)}
                        disabled={true}
                      />
                    </Label>
                  </div>
                  <div>
                    <Label
                      htmlFor="backdrop1"
                      style={{
                        width: "100%",
                        textAlign: "left",
                        display: "block",
                        marginBottom: "20px",
                        color: "#626469",
                      }}
                    >
                      {CONST.PHONE_NUMBER}
                      <br />
                      <div className="d-flex column">
                        <Row>
                          <Col lg={4}>
                            <AuthInput
                              type="text"
                              name="countryCode"
                              id="countryCode"
                              placeholder="+"
                              errors={errors}
                              handleChange={handleChange}
                              touched={touched}
                              values={values}
                            />
                          </Col>
                          <Col lg={8}>
                            <AuthInput
                              type="text"
                              name="phoneNumber"
                              id="phoneNumber"
                              placeholder="Insert phone number"
                              errors={errors}
                              handleChange={handleChange}
                              touched={touched}
                              values={values}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Label>
                  </div>
                  <div className="text-center">
                    {error && <span className="text-danger">{message}</span>}
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      type="submit"
                      color="none"
                      style={{ width: "82px" }}
                      onSubmit={(values) => handleSubmit(values)}
                      className="btn-color bt-5"
                    >
                      {CONST.SAVE}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
      {MessageModal(popUpMessage, openMessageModal, CONST.USER_PROGILE_MSG)}
    </div>
  );
};

export default EditProfileModal;
