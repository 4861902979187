import moment from "moment";
import React from "react";

const AcceptedShiftRow = ({ jobData }) => {
  return (
    <React.Fragment>
      <td>
        <span style={{ fontWeight: 700 }}>{jobData.description}</span>
        <br />
        <span>{jobData.streetName}</span>
      </td>
      <td>
        <span style={{ fontWeight: 700 }}>{`${jobData.fullName}`}</span>
        <br />
        <span>{`+${jobData.countryCode} ${jobData.phoneNumber}`}</span>
      </td>
      <td>{moment.utc(jobData.dateTime).format("MMMM DD YYYY hh:mm a")}</td>
    </React.Fragment>
  );
};
export default AcceptedShiftRow;
