import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import TransactionList from "../../../components/Transaction/TransactionList";
import CreditBalance from "../../../components/Transaction/CreditBalance.jsx";
import queryString from "query-string";
import TopUpSuccess from "../../../components/Transaction/TopUpSuccess";
import { getWalletDetails } from "services/Transaction";
import { constants as ROUTE_CONST } from "constants/routes";

const Transaction = (props) => {
  const [credit, setCredit] = useState(0.0);

  const [topUp, setTopUp] = useState(false);
  const topUpToggle = () => {
    setTopUp(!topUp);
  };
  const fetchData = () => {
    getWalletDetails().then((res) => {
      setCredit(res.data.data.data.credit);
    });
  };

  useEffect(() => {
    const query = queryString.parse(props.location.search);
    if (query.reference && query.status === "completed") {
      topUpToggle();
    }

    fetchData();
  }, []);
  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Transaction</h1>
              </div>
            </div>
            <Col xs={12} md={12} className="pl-4 pr-4">
              <div className="pl-1 pr-1 rounded">
                <CreditBalance image={true} credit={credit} />
              </div>
            </Col>
          </Col>
          <Col xs={12} md={12}>
            <div className="page-sub-title">
              <div className="float-left">
                <h1 className="title">Transaction List</h1>
              </div>
            </div>
            <Col xs={12} md={12}>
              <TransactionList />
            </Col>
          </Col>
        </Row>
      </div>
      {TopUpSuccess(topUp, topUpToggle, ROUTE_CONST.TRANSACTION)}
    </div>
  );
};

export default Transaction;
