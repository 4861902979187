import axios from "axios";
// import { URL } from "./authUrl";
import { constants as CONST } from "../constants/constants";
import { constants as ROUTE_CONST } from "constants/routes";
export const Bearer = "Bearer ";

const URL = process.env.REACT_APP_BACKEND_URL;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.clear();
      window.location.assign(ROUTE_CONST.LOGIN);
    } else {
      return Promise.reject(error);
    }
  }
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: (url) => {
    return axios.get(URL + url);
  },

  post: (url, data) => {
    return axios.post(URL + url, data);
  },

  postWithToken: (url, data) => {
    return axios({
      method: "post",
      data: data,
      url: URL + url,
      headers: {
        authorization: Bearer + localStorage.getItem(CONST.ACCESSTOKEN),
      },
    });
  },

  postWithParamsToken: (url, params) => {
    return axios({
      method: "post",
      params: params,
      url: URL + url,
      headers: {
        authorization: Bearer + localStorage.getItem(CONST.ACCESSTOKEN),
      },
    });
  },

  getWithParams: (url, params) => {
    return axios({
      method: "get",
      params,
      url: URL + url,
    });
  },

  getWithParamsToken: (url, params) => {
    return axios({
      method: "get",
      url: URL + url,
      params,
      headers: {
        authorization: Bearer + localStorage.getItem(CONST.ACCESSTOKEN),
      },
    });
  },

  getWithToken: (url) => {
    return axios({
      method: "get",
      url: URL + url,
      headers: {
        authorization: Bearer + localStorage.getItem(CONST.ACCESSTOKEN),
      },
    });
  },

  putWithToken: (url, data) => {
    return axios({
      method: "put",
      data: data,
      url: URL + url,
      headers: {
        authorization: Bearer + localStorage.getItem(CONST.ACCESSTOKEN),
      },
    });
  },
};
