import moment from 'moment';
import React, { useEffect, useState } from 'react';
import './style.css'
export const useDate = (events, nav, currentDate, clicked) => {
    const [dateDisplay, setDateDisplay] = useState('');
    const [days, setDays] = useState([]);
    const [items, setItems] = useState([]);
    console.log("items", items);

    const eventForDate = date => events.find(e => e.date === date);
    // console.log("eventForDate", eventForDate(clicked));

    const getStartDate = (firstDate) => {
        let stateDate = firstDate;
        for (let i = 0; i <= 10; i++) {
            if (moment(stateDate).weekday() === 0) {
                return stateDate;
            } else {
                stateDate = moment(stateDate).subtract(1, 'days')
            }
        }
    }


    const getEndDate = (lastDate) => {
        let stateDate = lastDate;
        for (let i = 0; i <= 10; i++) {
            if (moment(stateDate).weekday() === 6) {
                return stateDate;
            } else {
                stateDate = moment(stateDate).add(1, 'days')
            }
        }
    }

    useEffect(() => {
        const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        // console.log('nav', nav);
        console.log('currentDate', currentDate);

        const dt = moment(currentDate).add(nav, 'months');
        // console.log('dtdtdtdt', dt);
        // if (nav !== 0) {
        //     const dt = moment().add(nav, 'months');
        // }


        const day = dt.format("DD");
        const month = dt.format("MM");
        const year = dt.format("YYYY");

        // let firstDayOfMonth = new Date(year, month, 1);
        let firstDayOfMonth = moment(dt.startOf('month').format('YYYY-MM-DD hh:mm:ss'));
        let lastDayOfMonth = moment(dt.endOf('month').format('YYYY-MM-DD hh:mm:ss'));

        // let dateString = firstDayOfMonth.toLocaleDateString('en-us', {
        //     weekday: 'long',
        //     year: 'numeric',
        //     month: 'numeric',
        //     day: 'numeric',
        // });

        setDateDisplay(`${dt.format('MMMM')} ${dt.format('YYYY')}`);

        // const paddingDays = weekdays.indexOf(dateString.split(', ')[0]);
        // let lastDayOfMonth = new Date(dt.format('YYYY'), dt.getMonth() + 1, 0);
        // dateString = lastDayOfMonth.toLocaleDateString('en-us', {
        //     weekday: 'long',
        //     year: 'numeric',
        //     month: 'numeric',
        //     day: 'numeric',
        // });
        // console.log('moment(firstDayOfMonth).weekday()', moment(firstDayOfMonth).weekday());

        if (moment(firstDayOfMonth).weekday() !== 0) {
            firstDayOfMonth = getStartDate(firstDayOfMonth);
        }

        if (moment(lastDayOfMonth).weekday() !== 6) {
            lastDayOfMonth = getEndDate(lastDayOfMonth);
        }

        const daysArr = [];

        // for (let i = 1; i <= paddingDays + daysInMonth; i++) {
        //     const dayString = `${month + 1}/${i - paddingDays}/${year}`;

        //     if (i > paddingDays) {
        //         daysArr.push({
        //             value: i - paddingDays,
        //             event: eventForDate(dayString),
        //             isCurrentDay: i - paddingDays === day && nav === 0,
        //             date: dayString,
        //         });
        //     } else {
        //         daysArr.push({
        //             value: 'padding',
        //             event: null,
        //             isCurrentDay: false,
        //             date: '',
        //         });
        //     }
        // }

        function getMonthEnd(date = new Date()) {
            return new Date(date.getFullYear(), date.getMonth() + 1, 0);
        }

        function getMonthDaysLeft(date = new Date()) {
            return getMonthEnd(date).getDate() - date.getDate();
        }

        let prossDate = firstDayOfMonth;
        console.log(" moment(prossDate).format('MM')", moment(prossDate).format('MM'), moment(dt).format('MM/DD/YYYY'));
        let dateDiff = Math.round((lastDayOfMonth - firstDayOfMonth) / (1000 * 60 * 60 * 24));
        for (let i = 0; i <= dateDiff; i++) {
            daysArr.push({
                // value: i - paddingDays,
                // event: eventForDate(dayString),
                event: eventForDate(getMonthDaysLeft()),
                isCurrentDay: moment(prossDate).format('MM/DD/YYYY') === moment(dt).format('MM/DD/YYYY'),
                isCurrentMonth: moment(prossDate).format('MM') === moment(dt).format('MM'),
                date: moment(prossDate),
                currDate: prossDate.format("DD"),
            });
            prossDate = moment(prossDate).add(1, 'days');

        }

        setDays(daysArr);
    }, [events, nav]);

    return {
        days,
        dateDisplay
    };
};