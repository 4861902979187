import React, { useEffect, useState } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import { getTransactionList } from "../../services/Transaction";
import { PaginationFunction } from "../PaginationFunction";

const transactionsList = [
  {
    name: <div style={{ fontSize: 16, fontWeight: 600 }}>Date</div>,
    selector: (row) => (
      <div style={{ fontSize: 16 }}>
        {moment.utc(row.transactionTime).format("DD MMMM YYYY, hh:mm A")}
      </div>
    ),
    width: "25%",
  },
  {
    name: <div style={{ fontSize: 16, fontWeight: 600 }}>Description</div>,
    selector: (row) => <div style={{ fontSize: 16 }}>{row.description} </div>,
    width: "50%",
  },
  {
    name: <div style={{ fontSize: 16, fontWeight: 600 }}>Amount</div>,
    selector: (row) => <div style={{ fontSize: 16 }}>{`$ ${row.amount}`}</div>,
    width: "25%",
  },
];

const TransactionList = (props) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const perPage = 10;
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    fetchData(activePage, perPage);
  }, []);

  const fetchData = async (page, limit) => {
    const params = { page, limit };
    getTransactionList(params).then(
      (result) => {
        setIsLoaded(true);
        setItems(result.data.data.data);
        setTotalRows(result.data.data.count);
      },
      (err) => {
        const errorMessage =
          err && err.data && err.data.error && err.data.error.message;
        setIsLoaded(true);
        setError(true);
        if (!err.data) {
          setErrorMessage("Network Error");
        } else {
          setErrorMessage(errorMessage);
        }
      }
    );
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    fetchData(page, perPage);
  };

  if (error) {
    return <div className="text-danger pl-3">Error: {errorMessage}</div>;
  } else if (!isLoaded) {
    return <div className="pl-3">Loading...</div>;
  } else {
    return (
      <div className="rounded">
        <DataTable
          columns={transactionsList}
          data={items}
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
        />
        <div
          style={{
            width: "fit-content",
            display: "flex",
            justifyItems: "flex-start",
            marginTop: "16px"
          }}
        >
          <PaginationFunction
            activePage={activePage}
            totalCount={totalRows}
            handlePageChange={handlePageChange}
            pageDataCount={perPage}
          />
        </div>
      </div>
    );
  }
};

export default TransactionList;
